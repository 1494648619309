import React from "react";
import { DateRangePicker, MultiCascader, SelectPicker } from "rsuite";
import { predefinedBottomRanges } from "../../helper";

const TableExtraOption = ({
  type,
  className = "custom-header-input",
  placeholder,
  searchable,
  defaultValue,
  onChange,
  hoverRange,
  onCheck,
  onClean,
  value,
  data,
}) => {
  if (type === "dateRangePicker") {
    return (
      <DateRangePicker
        className={className}
        onChange={onChange}
        ranges={predefinedBottomRanges}
        defaultValue={defaultValue}
        value={value}
        format="MM-dd-yyyy"
        placeholder={placeholder}
        block
        placement="auto"
        showOneCalendar
        {...(hoverRange && { hoverRange, oneTap: true })}
      />
    );
  } else if (type === "multiCascader") {
    return (
      <MultiCascader
        data={data}
        className={className}
        placeholder={placeholder}
        defaultValue={value}
        onCheck={onCheck}
        onClean={onClean}
      />
    );
  } else if (type === "select") {
    return (
      <SelectPicker
        data={data}
        searchable={searchable}
        className={className}
        placeholder={placeholder}
        defaultValue={value}
        onCheck={onCheck}
        onChange={onChange}
        onClean={onClean}
      />
    );
  }
  return null;
};

export default TableExtraOption;
