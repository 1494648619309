import styled from "styled-components";

export const CursorWrapper = styled.div`
  position: absolute;
  /* background: black; */
  width: 20px;
  height: 20px;
  z-index: 1000;
  border-radius: 10px;

  &:before {
    display: block;
    content: "${(props) => (props.text ? `${props.text}` : "User")}";
    color: ${(props) => (props.color ? `${props.color}` : "white")};
  }
`;
