import { get, post } from "./ServicesApi";

const createClientTypes = (body) => {
  const regionPromise = post("/v1/client-type", body);
  return regionPromise;
};

const getClientTypes = (body) => {
  const regionPromise = get("/v1/client-type", body);
  return regionPromise;
};

export { createClientTypes, getClientTypes };
