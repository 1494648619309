import React, { useContext, useState } from "react";
import { AppContext } from "../../provider/AppContext";
import { Form } from "antd";
import { generateToast } from "../../helper";
import { ERROR, SUCCESS, TOP_RIGHT } from "../../constant";
import { updateProfile } from "../../services/AuthServices";
import { useTheme } from "../../ThemeContext";
import { Wrapper } from "../../components/Setting/styles";
const UpdateUserDetails = () => {
  const { setUser, user } = useContext(AppContext);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const themeToggle = useTheme();
  const mode = themeToggle.themeState?.mode;

  const onFinish = async (values) => {
    setIsLoading(true);
    const updateProfilePromise = updateProfile({
      ...values,
    });

    updateProfilePromise
      .then((res) => {
        setIsLoading(false);
        setUser(res.employee);
        generateToast("Profile updated successfully", SUCCESS, TOP_RIGHT);
      })
      .catch((err) => {
        localStorage.removeItem("token");
        setIsLoading(false);
        generateToast(err, ERROR, TOP_RIGHT);
      });
  };

  const { firstName, lastName, username, role } = user || {};

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <Wrapper background={mode === "dark" ? "#161616" : "white"}>
          <h3
            style={{
              textAlign: "center",
              padding: "20px",
              fontWeight: 900,
              color: mode === "dark" ? "#E6E6E6" : "black",
              textTransform: "none",
            }}
          >
            Edit Profile
          </h3>
          <Form
            initialValues={{ firstName, lastName, username, role }}
            layout="vertical"
            className="tw-w-full tw-mt-3 tw-px-5"
            form={form}
            onFinish={onFinish}
          >
            <div className="tw-input_wrapper">
              <div className="tw-flex tw-justify-between">
                <div style={{ width: "48%" }}>
                  {/* <span
                    className="input_lable"
                    style={{
                      color: mode === "dark" ? "#E6E6E6" : "black",
                    }}
                  >
                    First Name:
                  </span> */}
                  <Form.Item
                    hasFeedback
                    name="firstName"
                    label=" First Name:"
                    rules={[
                      {
                        required: true,
                        message: "Please input your first name!",
                      },
                    ]}
                  >
                    <input
                      className="tw-leading-5 tw-w-full  tw-mt-2 tw-bg-transparent tw-focus:tw-outline-0 tw-border-2 tw-border-gray-300 tw-text-gray-900 tw-rounded-lg tw-focus:tw-border-gray-500 tw-block tw-py-2.5 tw-px-2"
                      style={{
                        color: mode === "dark" ? "#E6E6E6" : "black",
                      }}
                    />
                  </Form.Item>
                </div>
                <div style={{ width: "48%" }}>
                  {/* <span
                    className="input_lable"
                    style={{
                      color: mode === "dark" ? "#E6E6E6" : "black",
                    }}
                  >
                    Last Name:
                  </span> */}
                  <Form.Item
                    hasFeedback
                    label=" Last Name:"
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your last name!",
                      },
                    ]}
                  >
                    <input
                      placeholder="Enter your last name"
                      className="tw-leading-5 tw-w-full  tw-mt-2 tw-bg-transparent tw-focus:tw-outline-0 tw-border-2 tw-border-gray-300 tw-text-gray-900 tw-rounded-lg tw-focus:tw-ring-blue-500 tw-focus:tw-border-gray-500 tw-block tw-py-2.5 tw-px-2"
                      style={{
                        color: mode === "dark" ? "#E6E6E6" : "black",
                      }}
                    />
                  </Form.Item>
                </div>
              </div>

              {/* <span
                className="input_lable"
                style={{
                  color: mode === "dark" ? "#E6E6E6" : "black",
                }}
              >
                Username:
              </span> */}
              <Form.Item
                hasFeedback
                label=" Username:"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <input
                  placeholder="Enter your username"
                  className="tw-leading-5 tw-w-full  tw-mt-2 tw-bg-transparent tw-focus:tw-outline-0 tw-border-2 tw-border-gray-300 tw-text-gray-900 tw-rounded-lg tw-focus:tw-ring-blue-500 tw-focus:tw-border-gray-500 tw-block tw-py-2.5 tw-px-2"
                  style={{
                    color: mode === "dark" ? "#E6E6E6" : "black",
                  }}
                />
              </Form.Item>
              {/* <span
                className="input_lable"
                style={{
                  color: mode === "dark" ? "#E6E6E6" : "black",
                }}
              >
                Role <span>(Not Editable)</span>:
              </span> */}
              <Form.Item
                hasFeedback
                label="Role (Not Editable)"
                name="role"
                rules={[
                  {
                    required: true,
                    message: "Please input your role!",
                  },
                ]}
              >
                <input
                  disabled
                  placeholder="Enter your role"
                  className="tw-leading-5 opacity-2 tw-w-full tw-mt-2 tw-focus:tw-outline-0 tw-border-2 tw-border-gray-300 tw-text-gray-900 tw-rounded-lg tw-focus:tw-ring-blue-500 tw-focus:tw-border-gray-500 tw-block tw-py-2.5 tw-px-2 tw-bg-gray-200"
                />
              </Form.Item>
              <Form.Item>
                <button
                  onClick={() => form.submit()}
                  className={`tw-rounded-md tw-text-white tw-text-xl tw-py-2 tw-mt-3 tw-w-full tw-border-0 tw-bg-blue ${isLoading && "tw-cursor-not-allowed tw-opacity-50"
                    }`}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Update"}
                </button>
                {/* <button
                  onClick={() => form.resetFields()}
                  className="tw-rounded-md tw-text-white tw-text-xl tw-py-2 tw-mt-3 tw-w-full tw-border-0 tw-bg-lightGrey"
                >
                  Cancel
                </button> */}
              </Form.Item>
            </div>
          </Form>
        </Wrapper>
      </div>
    </>
  );
};

export default UpdateUserDetails;
