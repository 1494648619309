import React from "react";
import lightmodeillustration from "./assets/lightmodeillsutration.png";
import darkmodeillustration from "./assets/darkmodeillustration.png";
const NoDataFound = ({ mode }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        flexDirection: "column",
        backgroundColor: mode === "dark" ? "#1E1E1E" : "white",
      }}
    >
      <img
        src={mode === "dark" ? darkmodeillustration : lightmodeillustration}
        height="25%"
        width="25%"
        alt="Not found"
      />
      <h4
        style={{
          color: mode === "dark" ? "white" : "black",
          padding: "20px",
        }}
      >
        No Search Found!
      </h4>
    </div>
  );
};

export default NoDataFound;
