import { convertStringToNumber } from ".";

export const getCosts = (summary, context, addAccountCost) => {
  const { contractTotal: total, getUpFrontCost, totalAccountCost } = context;
  const contractTotal = addAccountCost ? total + totalAccountCost : total;
  const { clientBudget, title } = summary || "";
  const isParentEvent = !!title;
  const upfront_cost = getUpFrontCost(summary);
  const profit = isParentEvent
    ? clientBudget - summary.totalCost
    : clientBudget - contractTotal - upfront_cost;
  const totalProfit = convertStringToNumber(profit);
  const profitMargin = (totalProfit / clientBudget) * 100;
  const fixedProfitMargin = clientBudget
    ? convertStringToNumber(profitMargin)
    : 0;
  const totalCost = isParentEvent
    ? summary.totalCost
    : contractTotal + upfront_cost;
  const needed = convertStringToNumber(totalCost * 1.55 - clientBudget);

  const costNeeded = convertStringToNumber(
    totalCost - (clientBudget - clientBudget * 0.35)
  );

  return {
    upfront_cost,
    totalProfit,
    fixedProfitMargin,
    needed,
    costNeeded,
    contractTotal,
  };
};

export const getChildLinkCosts = (
  summary,
  context,
  totalClientBudget,
  totalChildCost
) => {
  const { contractTotal, getUpFrontCost } = context;

  const budget = convertStringToNumber(totalClientBudget);
  const upfront_cost = getUpFrontCost(summary);
  const profit = budget - contractTotal - upfront_cost - totalChildCost;

  const totalProfit = convertStringToNumber(profit);

  // Updated calculation
  const totalCost = contractTotal + upfront_cost + totalChildCost;
  const needed = convertStringToNumber(totalCost * 1.55 - budget);

  const profitMargin = (totalProfit / budget) * 100;
  const fixedProfitMargin = budget ? convertStringToNumber(profitMargin) : 0;

  const costNeeded = convertStringToNumber(
    totalCost - (budget - budget * 0.35)
  );

  return {
    upfront_cost,
    totalProfit,
    fixedProfitMargin,
    needed,
    costNeeded,
    contractTotal,
    totalClientBudget,
    totalChildCost,
  };
};

export const CalculateCosts = (summary) => {
  let totalESCost = summary?.staffBillings?.reduce((sum, row) => {
    return (
      sum +
      convertStringToNumber(row.charges) * convertStringToNumber(row.quantity)
    );
  }, 0);
  let totalTransCost = summary?.transportationBillings?.reduce((sum, row) => {
    return (
      sum +
      convertStringToNumber(row.charges) * convertStringToNumber(row.quantity) +
      convertStringToNumber(row?.overtime) +
      getShuttleCostQty(row)
    );
  }, 0);

  let totalPCost = summary?.productionBillings?.reduce((sum, row) => {
    return (
      sum +
      convertStringToNumber(row.charges) * convertStringToNumber(row.quantity)
    );
  }, 0);

  let totalAccountCost = summary?.postEventExpenses?.reduce((sum, row) => {
    return (
      sum +
      convertStringToNumber(row.cost) * convertStringToNumber(row.quantity)
    );
  }, 0);

  return { totalESCost, totalPCost, totalTransCost, totalAccountCost };
};

export const getUpFrontCost = (propsSummary) => {
  const summary = propsSummary;
  const {
    expenses,
    finalBarSales,
    barGuarantee,
    clientResponsibility,
    shouldConsiderClientResponsibility,
  } = summary || {};

  const processExpenses = () => {
    const beverage = expenses
      ?.filter((obj) => obj.expenseType?.name === "Beverage")
      .sort((a, b) => a.id - b.id);

    const food = expenses
      ?.filter((obj) => obj.expenseType?.name === "Food")
      .sort((a, b) => a.id - b.id);

    const rental = expenses
      ?.filter((obj) => obj.expenseType?.name === "Misc")
      .sort((a, b) => a.id - b.id);

    return { beverage, food, rental };
  };

  const { beverage, rental, food } = processExpenses();

  const sumRentalCost = rental?.map(({ cost }) => cost);
  const sumFoodCost = food?.map(({ cost }) => cost);
  const sumBevCost = beverage?.map(({ cost }) => cost);

  const rental_cost = sumRentalCost?.reduce(
    (a, b) => convertStringToNumber(a || 0) + convertStringToNumber(b || 0),
    0
  );

  const food_cost = sumFoodCost?.reduce(
    (a, b) => convertStringToNumber(a || 0) + convertStringToNumber(b || 0),
    0
  );

  const bev_cost = sumBevCost?.reduce(
    (a, b) => convertStringToNumber(a || 0) + convertStringToNumber(b || 0),
    0
  );

  function TotalBevCost() {
    const isGambleSucceed =
      convertStringToNumber(finalBarSales) >
      convertStringToNumber(barGuarantee);
    if (!convertStringToNumber(finalBarSales)) {
      return getTPEResp(summary) + bev_cost;
    } else if (isGambleSucceed) {
      return 0 + bev_cost;
    }

    const clientRespDiff =
      clientResponsibility - finalBarSales >= 0
        ? clientResponsibility - finalBarSales
        : 0;

    const clientDiff = shouldConsiderClientResponsibility ? clientRespDiff : 0;
    return (
      convertStringToNumber(bev_cost) +
      convertStringToNumber(barGuarantee - finalBarSales - clientDiff)
    );
  }

  const bevTotalCost = TotalBevCost();

  return rental_cost + food_cost + bevTotalCost || 0;
};

export const getShuttleCostQty = (rowData) => {
  if (!rowData.shuttle || !rowData.quantity) return 0;
  return rowData?.shuttle * rowData?.shuttleRate || 0;
};

export function getOvertimeRate(
  transportationType,
  price,
  transportationTime,
  quantity
) {
  let overtimeThreshold = 5;
  let overtimeBaseRatio = 5;
  if (transportationType === "Party Bus") {
    overtimeThreshold = 4;
    overtimeBaseRatio = 4;
  }

  if (transportationTime > overtimeThreshold) {
    const overtimeHours = transportationTime - overtimeThreshold;
    return (price / overtimeBaseRatio) * overtimeHours * quantity;
  }
  return 0;
}

export const calculateTotalRowQuantity = (row) => {
  return (
    convertStringToNumber(row.values.quantity) +
    convertStringToNumber(row.values.shuttle)
  );
};

export const getTPEResp = (summary) => {
  const { barGuarantee, clientResponsibility } = summary;
  const assumed_spend = getAssumedSpend(summary);
  const result = barGuarantee - clientResponsibility - assumed_spend;
  return result > 0 ? result : 0;
};

export const getAssumedSpend = (summary) => {
  const { clientResponsibility, guestCount } = summary;

  if (!convertStringToNumber(clientResponsibility)) return guestCount * 7;
  else return 0;
};

export const convertNumToFormatString = (num, decimal = 2) => {
  return num?.toLocaleString("en-US", { minimumFractionDigits: decimal });
};

export const getBeveragesCost = (propsSummary) => {
  const summary = propsSummary;
  const {
    expenses,
    finalBarSales,
    barGuarantee,
    clientResponsibility,
    shouldConsiderClientResponsibility,
  } = summary || {};

  const beverage = expenses
    ?.filter((obj) => obj.expenseType?.name === "Beverage")
    .sort((a, b) => a.id - b.id);

  const sumBevCost = beverage?.map(({ cost }) => cost);

  const bev_cost = sumBevCost?.reduce(
    (a, b) => convertStringToNumber(a || 0) + convertStringToNumber(b || 0),
    0
  );

  function TotalBevCost() {
    const isGambleSucceed =
      convertStringToNumber(finalBarSales) >
      convertStringToNumber(barGuarantee);
    if (!convertStringToNumber(finalBarSales)) {
      return getTPEResp(summary) + bev_cost;
    } else if (isGambleSucceed) {
      return 0 + bev_cost;
    }

    const clientRespDiff =
      clientResponsibility - finalBarSales >= 0
        ? clientResponsibility - finalBarSales
        : 0;

    const clientDiff = shouldConsiderClientResponsibility ? clientRespDiff : 0;
    return (
      convertStringToNumber(bev_cost) +
      convertStringToNumber(barGuarantee - finalBarSales - clientDiff)
    );
  }

  const bevTotalCost = TotalBevCost();

  return bevTotalCost || 0;
};
