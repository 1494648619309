import React, { useEffect, useState } from "react";
import { Modal, Button } from "rsuite";
import calendarIcon from "./assets/calendarIcon.png";
import { diffChars } from "diff";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const CalendarDetailsModal = ({
  show,
  handleClose,
  currentData,
  setUpdatedCalendarData,
  updatedData,
  handleUpdate,
}) => {
  const [editorContent, setEditorContent] = useState(updatedData);

  const highlightRemovedWords = (current, updated) => {
    const diffResult = diffChars(current, updated);

    let highlightedText = "";
    diffResult.forEach((part) => {
      if (part.removed) {
        highlightedText += `<span style="background-color: pink;">${part.value}</span>`;
      } else if (part.added) {
        highlightedText += `<span style="background-color: lightgreen;">${part.value}</span>`;
      } else {
        highlightedText += part.value;
      }
    });

    return highlightedText;
  };

  useEffect(() => {
    setEditorContent(updatedData);
  }, [show, updatedData]);

  const handleEditorChange = (content) => {
    setEditorContent(content);
    setUpdatedCalendarData(content);
  };

  const highlightedText = highlightRemovedWords(
    currentData,
    updatedData?.replace(/<\/?[^>]+(>|$)/g, "")
  );
  return (
    <Modal open={show} onClose={handleClose} size="md">
      <Modal.Header>
        <Modal.Title>
          <div
            style={{
              display: "flex",
            }}
          >
            <img
              src={calendarIcon}
              style={{
                width: "30px",
                height: "30px",
              }}
              alt="Calendar Icon"
            />
            <span
              style={{
                marginLeft: "10px",
                fontWeight: "800",
                fontSize: "20px",
              }}
            >
              Google Calendar Details
            </span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "700px", marginBottom: "15px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <div
            style={{
              width: "45%",
            }}
          >
            <p
              style={{
                fontWeight: 900,
                fontSize: "18px",
                paddingBottom: "10px",
              }}
            >
              Current Data
            </p>
            <div
              style={{
                border: "1px solid #e0e0e0",
                borderRadius: "10px",
                padding: "10px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                minHeight: "410px",
                maxHeight: "410px",
                overflow: "auto",
              }}
            >
              <div
                dangerouslySetInnerHTML={{ __html: highlightedText }}
                style={{ padding: "5px" }}
              />
            </div>
          </div>
          <div
            style={{
              width: "45%",
            }}
          >
            <p
              style={{
                fontWeight: "bold",
                fontSize: "18px",
                paddingBottom: "10px",
              }}
            >
              Updated Data
            </p>
            <div>
              <ReactQuill
                value={editorContent}
                onChange={handleEditorChange}
                style={{
                  borderRadius: "10px",
                  border: "1px solid #e0e0e0",
                  padding: "10px",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  minHeight: "400px",
                  maxHeight: "410px",
                  overflow: "auto",
                }}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={handleUpdate}
          appearance="primary"
          style={{
            padding: "7px 24px",
            marginRight: "16px",
          }}
        >
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CalendarDetailsModal;
