import { get, post } from "./ServicesApi";

const createRegion = (body) => {
  const regionPromise = post("/v1/region", body);
  return regionPromise;
};

const getRegions = (body) => {
  const regionPromise = get("/v1/region", body);
  return regionPromise;
};

export { createRegion, getRegions };
