import { destroy, get, patch, post } from "./ServicesApi";

const getProductionDetails = (body) => {
  const productionPromise = get("/v1/production-pricing", body);
  return productionPromise;
};

const updateProductionDetails = (id, body) => {
  const productionPromise = patch(`/v1/production-pricing/${id}`, body);
  return productionPromise;
};

const createProductionDetails = (body) => {
  const productionPromise = post(`/v1/production-pricing`, body);
  return productionPromise;
};

const deleteProductionDetails = (id) => {
  const productionPromise = destroy(`/v1/production-pricing/${id}`);
  return productionPromise;
};

export {
  getProductionDetails,
  updateProductionDetails,
  createProductionDetails,
  deleteProductionDetails,
};
