import { get, patch } from "./ServicesApi";

const getNotificationCount = (body) => {
  const resetPromise = get(`/v1/notifications/count`, { params: body });
  return resetPromise;
};

const getNotifications = (body) => {
  const resetPromise = get(`/v1/notifications/`, { params: body });
  return resetPromise;
};

const handleRead = async (body) => {
  const resetPromise = await patch(`/v1/notifications/mark-as-read`, body);
  return resetPromise;
};

const handleAction = (body) => {
  const resetPromise = patch(`/v1/notifications/action`, body);
  return resetPromise;
};

export { getNotificationCount, getNotifications, handleRead, handleAction };
