import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

export { SortDirectionIcon, parseBool };

function SortDirectionIcon({ isExpanded, order }) {
  return (
    <>
      {isExpanded && (
        <>
          {order === "ASC" && <ArrowDropUpIcon className="arrow-disclosure" />}
          {order === "DESC" && (
            <ArrowDropDownIcon className="arrow-disclosure" />
          )}
        </>
      )}
    </>
  );
}

function parseBool(input) {
  if (input === "true") {
    return true;
  } else if (input === "false") {
    return false;
  } else return ""; // or false, or throw exception, or whatever
}
