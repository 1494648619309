import React, { useState, useEffect } from "react";

const Message = ({ messageDetail }) => {
  const [isShow, setIsShow] = useState(null);
  const [colorClass, setColorClass] = useState("");

  useEffect(() => {
    setIsShow(messageDetail);
    getMessageColor();
    // eslint-disable-next-line
  }, [messageDetail]);

  const getMessageColor = () => {
    if (messageDetail?.title === "Error") {
      setColorClass("tw-bg-errorBg tw-border-red-700");
    }
    if (messageDetail?.title === "Success") {
      setColorClass("tw-bg-successBg tw-border-green-700");
    }
  };

  return (
    <>
      {isShow && (
        <div
          className={`${colorClass} tw-mt-10 tw-relative tw-rounded-lg tw-border-2  tw-text-white tw-mx-auto tw-w-[271px] tw-flex tw-jutify-center tw-items-center tw-px-4 tw-h-[68px]`}
        >
          <i
            onClick={() => setIsShow(null)}
            className="fa-solid fa-xmark tw-absolute tw-top-2 tw-right-2 tw-cursor-pointer"
          ></i>
          <div>
            <i className="fa-sharp tw-text-3xl fa-solid fa-circle-xmark"></i>
          </div>
          <div className="tw-ml-4">
            <p className="tw-text-md tw-font-semibold tw-leading-3">
              {messageDetail.title}
            </p>
            <p className="tw-text-md tw-font-normal tw-leading-3">
              {messageDetail.message}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default Message;
