import { Fragment, useContext } from "react";
import { BrowserRouter } from "react-router-dom";
import WebRoutes from "./components/Routes/Routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootswatch/dist/lux/bootstrap.min.css";
import "antd/dist/antd.min.css";
import "rsuite/dist/rsuite.min.css";
import { Cursor } from "./components/Cursor/Cursor";
import { capitalize } from "lodash";
import { AppContext } from "./provider/AppContext";
import GlobalStyles from "./components/Kanban/GlobalStyles";
import { ConfigProvider } from "antd";
import { CookiesProvider } from "react-cookie";
import { CustomProvider } from "rsuite";
import { useTheme } from "./ThemeContext";
import { ThemeProvider } from "styled-components";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles";
import "./styles/App.css";

const ThemeConfigProvider = ({ theme, children }) => (
  <ThemeProvider theme={themeConfig[theme]}>{children}</ThemeProvider>
);

const themeConfig = {
  dark: {
    /* Dark theme styles */
  },
  light: {
    /* Light theme styles */
  },
};

const App = () => {
  const { activeUsers, user, getSummary, mapIndex, formTabIndex } =
    useContext(AppContext);
  const summary = getSummary(mapIndex);
  const themeToggle = useTheme();
  const theme = createTheme({
    palette: {
      mode: themeToggle.themeState?.mode,
    },
  });

  const activeUserCursors = activeUsers
    ?.filter(
      (userDetails) =>
        userDetails?.active_input?.point !== undefined &&
        userDetails?.active_input?.id === summary?.id &&
        userDetails?.active_input?.tabIndex === formTabIndex?.current &&
        userDetails.username !== user.username
    )
    ?.map((cursor) => (
      <Cursor
        key={cursor.id}
        color={cursor.color}
        text={capitalize(cursor.active_input.user)}
        point={cursor.active_input.point}
      />
    ));

  return (
    <CookiesProvider>
      <ThemeConfigProvider theme={themeToggle.themeState?.mode}>
        <CustomProvider theme={themeToggle.themeState?.mode}>
          <MuiThemeProvider theme={theme}>
            <ConfigProvider className="App">
              <ToastContainer />
              <GlobalStyles />
              {activeUserCursors}
              <Fragment>
                <BrowserRouter>
                  <WebRoutes />
                </BrowserRouter>
              </Fragment>
            </ConfigProvider>
          </MuiThemeProvider>
        </CustomProvider>
      </ThemeConfigProvider>
    </CookiesProvider>
  );
};

export default App;
