import React from "react";
import totalAvenue from "./assets/group1.png";
import totalCost from "./assets/Group 7.png";
import profitImg from "./assets/7789-02 1.png";
import marginImg from "./assets/Group 8.png";

const Statistics = ({
  TOTALCOST,
  PROFITMARGIN,
  TOTALPROFIT,
  TOTALREV,
  mode,
}) => (
  <div className="container-fluid shadow-sm" style={{ borderRadius: "15px" }}>
    <div className="row mb-2">
      {[
        {
          img: totalAvenue,
          value: TOTALREV,
          label: "TOTAL REVENUE",
          bgColor: mode === "dark" ? "#1E1E1E" : "#D3ECF7",
        },
        {
          img: totalCost,
          value: TOTALCOST,
          label: "TOTAL COST",
          bgColor: mode === "dark" ? "#1E1E1E" : "#F8DFDF",
        },
        {
          img: profitImg,
          value: TOTALPROFIT,
          label: "TOTAL PROFIT",
          bgColor: mode === "dark" ? "#1E1E1E" : "#DFF8E2",
        },
        {
          img: marginImg,
          value: PROFITMARGIN,
          label: "PROFIT MARGIN",
          bgColor: mode === "dark" ? "#1E1E1E" : "#EFDFF8",
        },
      ].map((statistic, index) => (
        <div
          key={index}
          className="col-md-3 d-flex p-3"
          style={{
            backgroundColor: statistic.bgColor,
            color: mode === "dark" ? "#fff" : "#3A4450",
            justifyContent: "center",
            alignItems: "center",
            border: mode === "dark" ? "1px solid #4C4C4C" : "none",
          }}
        >
          <div className="w-25 ms-2">
            <img src={statistic.img} alt={statistic.label} />
          </div>
          <div className="w-100 text-center me-3 ms-1">
            <h5>
              {statistic.label === "PROFIT MARGIN"
                ? `${statistic.value || 0}%`
                : `$${statistic.value || 0}`}
            </h5>
            <p>{statistic.label}</p>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default Statistics;
