import Cookies from "js-cookie";

export const retrieveFromCookieStorage = (key) => {
  const cookieValue = Cookies.get(key);
  try {
    return cookieValue ? JSON.parse(cookieValue) : "";
  } catch (error) {
    console.error(`Error parsing cookie value for key '${key}':`, error);
    return "";
  }
};

export const saveToCookieStorage = (key, value) => {
  Cookies.set(key, JSON.stringify(value));
};
