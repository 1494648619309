import { destroy, get, patch, post } from "./ServicesApi";

const getStaffDetails = (body) => {
  const staffPromise = get("/v1/staff-pricing", body);
  return staffPromise;
};

const updateStaffDetails = (id, body) => {
  const staffPromise = patch(`/v1/staff-pricing/${id}`, body);
  return staffPromise;
};

const createStaffDetails = (body) => {
  const staffPromise = post(`/v1/staff-pricing`, body);
  return staffPromise;
};

const deleteStaffDetails = (id) => {
  const staffPromise = destroy(`/v1/staff-pricing/${id}`);
  return staffPromise;
};

export {
  getStaffDetails,
  updateStaffDetails,
  deleteStaffDetails,
  createStaffDetails,
};
