import { destroy, get, patch, post } from "./ServicesApi";

const getTransportationDetails = (body) => {
  const transportationPromise = get("/v1/transportation-pricing", body);
  return transportationPromise;
};

const getTransportationType = (body) => {
  const transportationPromise = get("/v1/transportation-type", body);
  return transportationPromise;
};

const addTransportationType = (body) => {
  const transportationPromise = post("/v1/transportation-type", body);
  return transportationPromise;
};

const addTransportationCompany = (body) => {
  const transportationPromise = post("/v1/transportation-billing", body);
  return transportationPromise;
};

const updateTransportationDetails = (id, body) => {
  const transportationPromise = patch(`/v1/transportation-pricing/${id}`, body);
  return transportationPromise;
};

const createTransportationDetails = (body) => {
  const transportationPromise = post(`/v1/transportation-pricing`, body);
  return transportationPromise;
};

const deleteTransportationDetails = (id) => {
  const transportationPromise = destroy(`/v1/transportation-pricing/${id}`);
  return transportationPromise;
};

export {
  getTransportationDetails,
  updateTransportationDetails,
  createTransportationDetails,
  deleteTransportationDetails,
  getTransportationType,
  addTransportationType,
  addTransportationCompany,
};
