import { Button, Card } from "antd";
import styled from "styled-components";
import {
  TablebackgroundColor,
  backgroundColor,
  buttonTextColor,
  darkBackgroundColor,
  darkThemeGrey,
  lastWordColor,
  textColor,
  tableRowOdd,
  tableRowEven,
  AccordionTextColor,
  AccordionBackground,
  InputAdornmentIcon,
  OverviewTableColor,
  ShadeBg,
  BorderShade,
} from "./theme";
import { slide as Menu } from "react-burger-menu";

export const Wrapper = styled.div`
  background: ${backgroundColor};
  color: ${textColor};
  min-height: 100vh;
  height: 100%;
  transition: all 0.5s linear;

  .max-input {
    border: 0.5px solid ${BorderShade};
    border-bottom: 2px solid rgb(171, 171, 171) !important;
  }

  .custom-header-icon {
    color: ${textColor};
  }

  .sub-entry-stats .card-section-container,
  .filterby-container {
    background-color: ${BorderShade};
    border-radius: 10px;
  }

  .vertical-chart-column {
    background-color: ${BorderShade};
  }

  .vertical-chart {
    box-shadow: rgba(255, 99, 132, 0.19) 0px 10px 20px,
      rgba(255, 99, 132, 0.23) 0px 6px 6px;
  }

  .layout-container {
    background: ${AccordionBackground} !important;
  }

  .date-range-wrapper {
    background-color: ${BorderShade};
    border-radius: 10px;
    padding: 5px;
    margin-bottom: 5px;
    display: flex;
  }

  .min-input {
    border: 0.5px solid ${BorderShade};
    border-bottom: 2px solid rgb(171, 171, 171) !important;
  }

  .image-wrapper {
    background-color: ${backgroundColor};
  }

  .image-wrapper-burger {
    background-color: ${backgroundColor};
  }

  .navbar {
    position: relative;
    background-color: ${darkBackgroundColor} !important;
    transition: all 0.5s linear;
  }

  .nav-item-color {
    background-image: radial-gradient(
      100% 100% at 100% 0,
      #c6c6c6 0,
      ${darkBackgroundColor} 100%
    ) !important;
  }

  .tab-new-container {
    background-color: ${darkBackgroundColor} !important;
    display: flex;
    // padding: 5px;
  }

  #li-error {
    background-color: lightcoral !important;
  }
  #li-success {
    background-color: lightgreen !important;
  }

  .notification-wrapper,
  .nav-link,
  .owner-dropdown {
    background-color: ${({ themeCardBackground }) =>
      themeCardBackground || textColor} !important;
    color: ${textColor} !important;
  }

  .last-word {
    color: ${lastWordColor} !important;
  }

  .last-word-fill {
    color: #82c9ff !important;
  }

  .dark-black {
    color: ${textColor} !important;
  }

  .font-medium {
    font-size: 50px !important;
    background: rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 8px;
  }

  li.tab.selected {
    border-top: 4px solid #7be279 !important;
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  li.tab.unselected {
    cursor: pointer;
  }

  ${({ themeContentColor }) =>
    themeContentColor === "#121212" &&
    `.accordion-button::after {
      filter: invert(0%) sepia(98%) saturate(0%) hue-rotate(317deg) brightness(0%)
      contrast(103%); 
    }
  `}

  .pagination {
    border-radius: 0 0 10px 10px;
    overflow: hidden;
    display: inline-flex;
    .page-link,
    select {
      background-color: rgba(0, 0, 0, 0.05) !important;
      font-weight: 400;
      color: ${({ themeContentColor }) =>
        themeContentColor || textColor} !important;
    }

    select {
      border-radius: 0px !important;
      height: 40px !important;
    }
  }

  .create-icons {
    display: flex;
    padding: 0px 5px 0px 5px;
    background: ${({ backgroundThemeColor }) =>
      backgroundThemeColor || darkThemeGrey} !important;
  }

  .contract-form {
    margin: auto;
    width: 100%;
  }

  .google-cal-text {
    padding: 3px 15px;
    cursor: pointer;
    text-align: right;
    color: ${textColor} !important;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .fade-in {
    animation: fadeIn 1.5s;
  }

  .loader-container {
    width: 100%;
    margin: auto;
    min-height: 15vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .prod-icon {
    font-size: 20px;
  }

  .prod-icon:hover {
    font-size: 20px;
    color: white !important;
    transition: color 0.2s ease-in-out;
  }
  .production-tab-conatiner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  .production-tabs {
    align-items: flex-start;
  }
  .production-tab {
    color: #b1b1b1;
    padding: 10px 0px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    transition: background-color 0.2s;
    width: 100%;
    margin-left: -15px;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  .production-tabs .production-tab.selected,
  .production-tabs .production-tab.selected:hover {
    color: #007bff;
    border-bottom: 2px solid #48b0db;
  }

  @media (min-width: 500px) {
    .production-tabs {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      margin-left: 0; /* Remove negative margin-left */
    }
  }

  .sub-entry-contract {
    display: flex;
    flex-direction: row-reverse;
    box-shadow: none !important;
  }
  .add-close-button {
    background-color: transparent !important;
    color: #48b0db !important;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 8px 0px;
  }
  .add-close-button-icon {
    color: #48b0db !important;
  }

  .reset-btn,
  .td-style-trans {
    background-color: ${({ themeCardBackground }) =>
      themeCardBackground || textColor} !important;
    color: ${({ textThemeColor }) =>
      textThemeColor || buttonTextColor} !important;
  }

  // .owner-dropdown:focus {
  //   background-color: lightblue !important;
  // }

  .MuiTabs-root {
    background-color: ${({ themeCardBackground }) =>
      themeCardBackground || darkThemeGrey} !important;
    color: ${buttonTextColor} !important ;
    border-bottom: none 0px !important;
    border-top: none 0px !important;
  }
  .MuiTabs-root .MuiButtonBase-root {
    color: ${({ themeContentColor }) =>
      themeContentColor || textColor} !important;
  }
  .MuiTabs-flexContainer {
    height: 10px !important;
  }

  .stack {
    border-radius: 0 0 15px 15px !important;
  }

  .rcp-light {
    --rcp-background: #ffffff;
    --rcp-input-text: #111111;
    --rcp-input-border: rgba(0, 0, 0, 0.1);
    --rcp-input-label: #717171;
  }
  .rcp-dark {
    --rcp-background: #181818;
    --rcp-input-text: #f3f3f3;
    --rcp-input-border: rgba(255, 255, 255, 0.1);
    --rcp-input-label: #999999;
  }

  .Mui-disabled {
    color: ${InputAdornmentIcon} !important;
  }

  .form-labels {
    font-weight: 500;
  }

  .sub-entry-event-one,
  .sub-entry-production-one,
  .sub-entry-venue-one,
  .form-container,
  .venue-accordion-container,
  .sub-entry-contract-event-staff,
  .sub-entry-payment-one {
    min-width: calc(100vw - 61vw) !important;
    width: calc(100vw - 50vw) !important;
    max-width: calc(100vw - 50vw) !important;

    color: ${({ textThemeColor }) =>
      textThemeColor || buttonTextColor} !important;
  }

  .transportation-accordion-container {
    min-width: calc(100vw - 61vw) !important;
    background: ${AccordionBackground};
    color: ${({ textThemeColor }) =>
      textThemeColor || buttonTextColor} !important;
  }

  .sub-entry-summary {
    max-width: calc(105vw - 61vw) !important;
    border-top: none 0px !important;
    border: none !important;
    color: ${({ textThemeColor }) =>
      textThemeColor || buttonTextColor} !important;
  }

  .sub-entry-todo-list {
    width: 40vw;
    min-width: 30vw;
    padding: 0px 20px;
    margin: 0px 20px 15px 20px;
    background: ${backgroundColor} !important;
    border: 1px solid #eceef0;
    box-shadow: 0px 4px 45px 0px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    max-height: 77vh !important;
    min-height: 77px !important;
    overflow-y: scroll;
  }

  .sub-entry-venue-one,
  .sub-entry-event-one {
    box-shadow: 0px 4px 55px 0px rgba(0, 0, 0, 0.08) !important;
    border-radius: 11px !important;
    border: 1px solid #eceef0 !important;
    padding: 1.5rem !important;
    max-height: 630px !important;
    overflow-y: scroll;
    background: ${backgroundColor};
    margin-top: 22px;
  }

  .sub-entry-payment-one {
    box-shadow: 0px 4px 55px 0px rgba(0, 0, 0, 0.08) !important;
    border-radius: 11px !important;
    border: 1px solid #eceef0 !important;
    height: fit-content;
    max-height: 571px !important;
    overflow-y: scroll;
    background: ${backgroundColor};
    margin-top: 22px;
  }

  .venue-details-header-form-container {
    display: flex;

    flex-wrap: wrap;
    margin-left: 8px;
  }

  .venue-time-container {
    display: flex;
    width: 13rem;
  }

  .payment-terms {
    display: flex;
    padding: 14px 48px 14px 32px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    background: rgba(72, 176, 219, 0.3);
  }
  .payment-terms p {
    color: #3a4450;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .payment-heading {
    color: ${textColor};
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
  }
  .add-payment {
    color: #48b0db;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 20px;
    cursor: pointer;
  }
  .remove-payment {
    color: #e27979 !important;
    cursor: pointer;
  }
  .todo-icon {
    margin: -2;
    margin-left: 1;
    color: #05ffa3;
    font-size: 20px;
    background-color: white;
    border-radius: 10px;
  }
  .todo-label {
    text-align: left;
    display: flex;
    gap: 3px;
    align-items: center;
    padding: 20px 10px 10px 10px;
    font-weight: 700;
    background: ${backgroundColor} !important;
    color: ${textColor} !important;
  }
  .todo-container {
    box-shadow: 0px 4px 45px 0px rgba(0, 0, 0, 0.08);
    border-left: 5px solid #65aed7;
    border-radius: 4px;
    border-bottom: 1px solid #eceef0;
    margin: 10px 0px;
  }
  .todo-li {
    text-align: left;
    padding: 1px 0px;
    color: ${textColor};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .contract-pdf-container {
    width: 50vw;
    height: 65vh;
    max-height: 450pt;
    min-height: 450pt;
    margin-bottom: 10px;
    background-color: none;
    border-radius: 10px;
    border: 3px solid white;
  }

  .start-date-day {
    color: ${textColor} !important;
  }
  .event-staff-table-container {
    overflow-y: scroll;
    background: ${backgroundColor};
    max-height: 655px !important;
    border-radius: 11px;
  }

  #form-control-input,
  #form-control-input-description,
  .hNkkDU,
  .client-detail-text {
    color: ${textColor} !important;
  }

  .checkbox-style {
    color: ${textColor};
    font-size: 14px;
  }
  .card {
    background: ${({ backgroundThemeColor }) =>
      backgroundThemeColor || darkThemeGrey} !important;
    border-radius: 11px !important;
  }

  .form-container {
    display: inline-flex;
    padding: 20px 25px;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    box-shadow: 0px 4px 55px 0px rgba(0, 0, 0, 0.08) !important;
    border-radius: 11px !important;
    border: 1px solid #eceef0 !important;
    background: #fff;
    width: "100%";
    background-color: ${backgroundColor};
  }
  .transportation-time-container {
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: 32px;
    padding: 0px 10px;
  }

  div .fancy-border {
    width: 30vw;
    height: 75px;
  }

  // .css-8crk02-MuiFormGroup-root {
  //   padding-top: 0px !important;
  // }

  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    margin-left: 20px;
  }

  .MuiPaper-root .MuiButtonBase-root {
    color: ${({ textThemeColor }) =>
      textThemeColor || buttonTextColor} !important;
  }

  .th-style {
    font-size: 0.65vw !important;
    white-space: nowrap;
  }

  .th-style .text-thead-span {
    align-items: center;
    appearance: none;
    background-image: radial-gradient(
      ${({ backgroundThemeColor, themeCardBackground }) =>
        `100% 100% at 100% 0,
      ${backgroundThemeColor || darkThemeGrey} 0,
      ${themeCardBackground} 100%`}
    );
    border-radius: 6px;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
      rgba(45, 35, 66, 0.3) 0 7px 13px -3px,
      rgba(58, 65, 111, 0.5) 0 -3px 0 inset;
    box-sizing: border-box;
    cursor: pointer;
    justify-content: center;
    list-style: none;
    padding: 1px 4px 4px 4px;
    text-decoration: none;
    transition: box-shadow 0.15s, transform 0.15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
  }

  .th-style .text-thead-span:focus {
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
      rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  }

  .th-style .text-thead-span:hover {
    box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
      rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px);
  }

  .th-style .text-thead-span:active {
    box-shadow: #3c4fe0 0 3px 7px inset;
    transform: translateY(2px);
  }

  .venue-accordion-container {
    background: ${AccordionBackground};
    margin: 10px 0px !important;
    width: 100%;
  }

  .payment-accordion-container {
    background: ${AccordionBackground};
    color: ${AccordionTextColor};
    padding: 25px 20px 10px 20px;
    width: 100%;
  }
  .table-accordion-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .table-prod-header-container {
    background: ${AccordionBackground};
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    border-top-left-radius: 11px !important;
    border-top-right-radius: 11px !important;
  }

  .table-prod-tab-container {
    background: ${AccordionBackground};
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    box-shadow: 0px 4px 55px 0px rgba(0, 0, 0, 0.08) !important;
    border-radius: 11px !important;
    border: 1px solid #eceef0 !important;
  }

  .table-accordion-heading-main {
    font-family: Poppins;
    font-size: 1.2vw;
    font-weight: 600;
    line-height: normal;
    align-self: flex-start;
    align-items: center;
    display: flex;
    flex-grow: 1;
    color: ${textColor} !important;
  }

  .table-accordion-heading-stats {
    color: #e27979;
    font-family: Poppins;
    font-size: 1.2vw;
    font-weight: 600;
    line-height: normal;
    align-self: flex-end;
    flex-shrink: 0;
  }
  .accordion-icon {
    color: ${InputAdornmentIcon};
  }
  .table-th {
    height: 25px;
    background: ${OverviewTableColor} !important;
    color: ${textColor};
    font-family: Poppins;
    font-size: 1vw;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    vertical-align: middle;
    padding: 0px 5px;
  }
  .td-venue {
    color: ${textColor} !important;
  }

  .td-venue:nth-child(4) {
    width: 7vw;
    color: ${textColor} !important;
  }
  .even-row {
    background-color: ${tableRowEven} !important;
  }

  .odd-row {
    background-color: ${tableRowOdd} !important;
  }

  .MuiTable-root {
    overflow: auto;
  }

  .venue-switch-textfeild {
    color: ${textColor} !important;
  }

  .td-style-venue,
  .td-style-trans {
    appearance: none;
    background-image: radial-gradient(
      ${({ backgroundThemeColor, themeCardBackground }) =>
        `100% 100% at 100% 0,
        ${backgroundThemeColor || darkThemeGrey} 0,
        ${themeCardBackground} 100%`}
    );
    border-radius: 6px;
    box-shadow: rgba(45, 35, 66, 0.2) 0 2px 4px,
      rgba(45, 35, 66, 0.1) 0 7px 13px -3px,
      rgba(58, 65, 111, 0.5) 0 -3px 0 inset;
    box-sizing: border-box;
    will-change: box-shadow, transform;
  }

  .form-entire,
  .sub-entry-summary,
  .sub-entry-event-one,
  .sub-entry-venue-one,
  .sub-entry-contract-event-staff,
  .sub-entry-transportation,
  .sub-entry-production-one {
    padding: 1rem;
  }

  .total-cost-table-header {
    display: flex;
    font-weight: 700 !important ;
    padding: 5px 20px;
    align-items: center;
    color: #e27979;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    line-height: normal;
  }

  .total-cost-event-staff-form {
    background: ${AccordionBackground};
    box-shadow: 0px 4px 55px 0px rgba(0, 0, 0, 0.08) !important;
    border-radius: 11px !important;
    border: 1px solid #eceef0 !important;
  }

  .td-style input[type="number"],
  #form-control-bev {
    color: ${textColor} !important;
  }

  .td-style input[type="text"] {
    color: ${textColor} !important;
  }

  .td-style-bev,
  .td-style-bev-bar {
    color: ${textColor} !important;
  }

  .td-style input,
  #form-control-bev {
    color: ${textColor} !important;
  }

  .accordion-body {
    padding: 0 !important;
    font-weight: 800 !important;
  }

  .black {
    color: #000;
  }

  /* DOWNLOAD PDF BUTTON */
  .contract-btn {
    display: flex;
    padding: 8px 27px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #f6f6f6;
    border: 1px solid #d3d3d3;
    color: black;
  }

  #add-btn {
    background-color: #2d3748 !important;
    margin: 5px 0 !important;
    font-weight: 700 !important;
    padding: 3px 15px !important;
    min-width: 120px !important;
  }

  .container-skeleton {
    padding: 20px 1rem 0 1rem;
  }

  .time-picker {
    margin-top: 11px;
    width: 100%;
    margin-right: 8px;
  }

  .time-picker.dark {
    margin-top: 10px;
    width: 100%;

    .rc-time-picker-input {
      background: transparent !important;
      color: #fff;
    }
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    background-color: ${backgroundColor} !important;
    border: none;
    width: 100%;
  }

  #travel-btn {
    width: 49%;
    height: 30px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${textColor};
    border-radius: 0px !important;
  }
  .css-15v22id-MuiAccordionDetails-root {
    padding: 0px;
  }

  .MuiAccordion-root {
    border-radius: 6px !important;
  }

  .button-icon {
    font-size: 18px;
    margin-right: 3px;
    color: white !important;
  }
  #trans-reset-btn {
    background: rgba(227, 166, 0, 0.6) !important;
    margin: 5px 10px !important;
    font-weight: 700 !important;
    padding: 3px 15px !important;
    min-width: 120px !important;
    font-size: 12px !important;
  }

  #pricing-acc-btn {
    // margin-top: 7px;
    color: ${textColor} !important;
    background-image: radial-gradient(
      100% 100% at 100% 0,
      #5adaff 0,
      #5468ff 100%
    ) !important;
  }

  #pricing-acc-btn:hover {
    background-color: rgba(255, 255, 255, 0.5) !important;
    transition: all ease-in-out 0.4s;
  }

  .accordion-button,
  .accordion-button:hover {
    font-size: 20px !important;
    font-weight: 550 !important;
    // height: 50px !important;
    padding: 0px !important;
    z-index: 0 !important;
    // margin-bottom: 2px !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 6px, rgba(0, 0, 0, 0.1) 0px 2px 6px !important;
    background: rgba(255, 255, 255, 0.1) !important;
  }

  .accordion-header,
  .accordion-header:hover {
    margin-bottom: -1px !important;
    line-height: 40px !important;
  }

  .list-group,
  .accordion-body {
    li {
      font-size: 14px !important;
      color: #000000 !important;
      font-weight: 600 !important;
      padding: 4px 10px !important;
    }
    li:nth-child(odd) {
      background: #fff !important;

      .prod {
        margin: 5px 0 !important;
        font-size: 11px !important;
        padding: 3px 15px !important;
        font-weight: 900 !important;
        min-width: 120px !important;
        border-radius: 4px !important;
        background-color: #2d3748 !important;
        color: #fff;
      }
    }
    li:nth-child(even) {
      background: #f2f2f2 !important;
      .prod {
        background-color: #fff !important;
        font-size: 11px !important;
        margin: 5px 0 !important;
        color: #000;
        font-weight: 900 !important;
        padding: 3px 15px !important;
        border-radius: 4px !important;
        min-width: 120px !important;
      }
    }
  }

  tr:nth-child(odd) {
    .prod {
      margin: 5px 0 !important;
      font-size: 11px !important;
      padding: 3px 15px !important;
      font-weight: 900 !important;
      min-width: 120px !important;
      border-radius: 4px !important;
      background-color: #2d3748 !important;
      color: #fff;
    }
  }

  .light-div {
    margin: 0;
    min-height: calc(100vh - 71px);
    font-weight: 100;
    background: ${({ backgroundThemeColor }) =>
      `radial-gradient(${backgroundThemeColor}, #1f1013)
  }`};
    -webkit-overflow-y: hidden;
    -moz-overflow-y: hidden;
    -o-overflow-y: hidden;
    overflow-y: hidden;
    -webkit-animation: fadeIn 1 1s ease-out;
    -moz-animation: fadeIn 1 1s ease-out;
    -o-animation: fadeIn 1 1s ease-out;
    animation: fadeIn 1 1s ease-out;
  }
  .sub-entry-stats,
  html,
  .sub-entry-venue-one,
  .event-staff-table-container,
  .sub-entry-production-one,
  .sub-entry-payment-one,
  .sub-entry-event-one,
  .sub-entry-payment,
  body {
    overflow-y: auto;
  }

  td.td-style {
    color: ${textColor} !important;
    font-weight: 700;
    font-size: 11px !important;
    height: 40px;
  }

  .shadow {
    border-radius: 0 0 20px 20px !important;
    box-shadow: ${({ themeContentColor }) =>
      `1px 1px  15px ${themeContentColor || textColor}8, 1px 1px 15px ${
        themeContentColor || textColor
      }8`} !important;
  }
  tr:nth-child(even) {
    /* background: #f2f2f2 !important; */
    .prod {
      background-color: #fff !important;
      font-size: 11px !important;
      margin: 5px 0 !important;
      color: #000;
      font-weight: 900 !important;
      padding: 3px 15px !important;
      border-radius: 4px !important;
      min-width: 120px !important;
    }
  }
  table th {
    // background-color: ${TablebackgroundColor} !important;
    font-weight: 800 !important;
    // letter-spacing: 1px !important;
    text-transform: uppercase;
    user-select: none;
  }

  .contract-overview-thead {
    background-color: ${TablebackgroundColor} !important;
  }

  .td-style.close {
    width: 40px !important;
  }
  #form-control-trans-label {
    font-size: 12px !important;
    text-align: center;
    margin-left: 10px;
    padding: 5px 0;
    border-radius: 4px !important;
    background-color: #e7e7e7;
    width: 15%;
    display: inline;
    color: black;
  }
  .success-input {
    background-color: #19875490 !important ;
    font-weight: 600;
  }
  .error-input {
    background-color: #ff003390 !important ;
    font-weight: 600;
    color: #fff !important;
  }

  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 15px;
    top: 15px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: ${({ textThemeColor }) =>
      textThemeColor || buttonTextColor} !important;
    border-radius: 2px !important;
  }

  .custom-header-title {
    color: ${textColor};
    background: ${AccordionBackground};
    // border: 1px solid;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: ${({ textThemeColor }) =>
      `${textThemeColor || buttonTextColor}90`} !important;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  /*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
  .bm-menu-wrap {
    position: fixed;

    height: 100%;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  @media only screen and (max-width: 1100px) {
    .navbar {
      display: none;
    }
  }

  @media only screen and (min-width: 1100px) {
    .menu-bar,
    .bm-burger-button {
      display: none;
    }
  }

  @media only screen and (max-width: 800px) {
    .shadow form {
      width: 100%;
    }
    .sub-entry-event-one,
    .sub-entry-production-one,
    .sub-entry-event-one,
    .sub-entry-venue-one,
    .sub-entry-contract-event-staff,
    .sub-entry-payment-one {
      padding-top: 25px !important;
      min-width: 100% !important;
      width: 100% !important;
      max-width: 100% !important;
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }

  .employee-select-wrapper {
    background: ${AccordionBackground} !important;
  }

  .pricing-td:last-child {
    border-right: none;
  }

  .pricing-td:first-child {
    border-left: none;
  }

  .pricing-td:hover {
    color: rgb(116, 146, 244) !important;
  }

  // .pricing-td:nth-child(11) {
  //   width: 10px !important;
  // }

  .native-select {
    padding: 0px !important;
    margin: 0px 0px 0px 0px;
    height: 25px !important;
    border-color: rgba(65, 64, 64, 0.5);
    font-size: 0.7vw;
    vertical-align: middle !important;
  }

  .form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .contract-nav {
    margin-bottom: 5px !important;
  }

  .client-tab {
    background-color: #d2b48c !important;
  }

  .tab-border:not(:last-child) {
    border-right: 1px solid grey;
  }

  .staff-pricing-header,
  .trans-pricing-header,
  .prod-pricing-header,
  .pricing-acc-span {
    color: ${textColor} !important;
  }

  .pricing-td {
    color: black !important;
    vertical-align: middle !important;
    text-align: center !important;
    font-weight: 600;
    border: outset 1px rgb(96, 96, 96);
    // font-size: 0.7vw !important;
    border-top: none !important;
  }

  // .pricing-td:first-child {
  //   width: 10px !important;
  // }

  .pricing-td:nth-child(2) {
    padding: 0px !important;
    margin: 0px !important;
    font-weight: 800 !important;
  }

  .contract-date {
    border: 1px solid black;
    cursor: pointer;
  }

  .shade-bg {
    background-color: ${ShadeBg} !important ;
  }

  .notification-wrapper {
    display: flex;
    margin-right: 16px;
    cursor: pointer;
    align-items: center;
    padding: 6px;
    border-radius: 6px;
  }

  .flex {
    display: flex;
  }
  .end {
    justify-content: flex-end;
  }
  .px-8 {
    padding: 0px 8px;
  }

  .Mui-error {
    border-color: none !important;
  }

  .container-fluid {
    border-radius: 15px;
  }

  @media (max-width: 767px) {
    .container-fluid .row > div:first-child {
      border-radius: 15px 15px 0 0;
    }

    .container-fluid .row > div:last-child {
      border-radius: 0 0 15px 15px;
    }
  }

  @media (min-width: 768px) {
    .container-fluid .row > div:first-child {
      border-radius: 15px 0 0 15px;
    }

    .container-fluid .row > div:last-child {
      border-radius: 0 15px 15px 0;
    }
  }
`;

export const BackgroundShade = styled.div`
  width: ${({ width }) => width};
  border-radius: 50px;
  cursor: pointer;
  height: ${({ height }) => height};
  background: ${({ BgOne, BgTwo, BgThree, BgFour }) =>
    `
    linear-gradient(to right,${BgOne} 0%,${BgOne} 50%,${BgTwo} 50%,${BgTwo} 100%),
    linear-gradient(to right,${BgThree} 0%,${BgThree} 50%,${BgFour} 50%,${BgFour} 100%)
    `};
  border: ${({ isActive, BgOne, isDark }) =>
    isActive ? `3px solid ${BgOne}` : `2px solid ${isDark ? "#fff" : "#000"}`};
  box-shadow: ${({ isActive, BgTwo }) =>
    isActive && `1px 1px 5px 5px ${BgTwo}90`};
  background-size: 100% 50%;
  background-position: center top, center bottom;
  background-repeat: no-repeat;
`;

export const ColorPatternWrapper = styled.div`
  display: flex;
  gap: 12px;
  margin-right: 15px;
`;

export const TDInput = styled.input`
  color: ${({ color }) => color || "black"} !important;

  :focus,
  :focus-within,
  :active,
  :valid {
    color: ${({ color }) => color || "black"} !important;
  }
`;

export const TDTextInput = styled.input`
  color: ${({ color }) => color || "black"} !important;
  :focus,
  :focus-within,
  :active,
  :valid {
    color: ${({ color }) => color || "black"} !important;
  }
`;

export const CustomCard = styled(Card)`
  border-top-left-radius: ${({ borderTopRadius }) =>
    !borderTopRadius && "0px !important"};

  .MuiInput-underline {
    width: 100% !important;
    padding-bottom: 10px !important;
    border-bottom-width: 0.5px !important;

    .MuiInput-input {
      margin-left: 16px;
    }
  }
`;
export const InputResetButton = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */
  text-align: right;
  letter-spacing: -0.02em;

  /* Secondary */
  margin-top: -6px;
  margin-bottom: 28px;
  color: #696a6b;

  span {
    color: #1c75bc;
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
`;

export const CustomButton = styled(Button)`
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.24);
  margin: ${({ padding }) => padding};
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-right: ${({ marginRight }) => marginRight};
  margin-left: ${({ marginLeft }) => marginLeft};
  background-color: ${({ background }) => background || "#8082df"};

  :hover,
  :focus {
    background-color: ${({ background }) => background || "#8082df90"};
  }
`;

export const RememberPasswordWrapper = styled(InputResetButton)`
  margin-top: 24px;
  text-align: center;
  margin-bottom: 10px;
`;

export const NotificationWrapper = styled(Menu)`
  h2 {
    color: #fff;
    text-transform: none;
    font-weight: 600;
  }

  .notification-content {
    padding: 10px 20px;
  }

  .notification-card {
    padding: 15px 20px 15px 20px;
    border-radius: 6px;
    background-color: ${({ themeCardBackground }) =>
      themeCardBackground || darkThemeGrey} !important;
    color: ${textColor} !important;
    display: flex;
    align-items: center;
    position: relative;
    flex-direction: row;
    gap: 16px;
    margin-bottom: 10px;
  }

  .avatar-div > div {
    color: ${textColor} !important;
  }

  .time {
    color: ${({ textThemeColor }) =>
      `${textThemeColor || buttonTextColor}`} !important;
  }

  .user-text {
    font-weight: 600;
    // font-size: 18px !important;
  }
`;

export const NotificationButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  min-width: ${({ width }) => width};
  margin: ${({ padding }) => padding};
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-right: ${({ marginRight }) => marginRight};
  margin-left: ${({ marginLeft }) => marginLeft};
  background-color: ${({ themeCardBackground }) =>
    themeCardBackground || textColor} !important;
  color: ${({ textThemeColor }) =>
    textThemeColor || buttonTextColor} !important;

  :hover,
  :focus {
    background-color: ${({ themeCardBackground }) =>
      `${themeCardBackground || textColor}90`} !important;
    color: ${({ textThemeColor }) =>
      textThemeColor || buttonTextColor} !important;
    opacity: 0.8;
  }
`;

export const NotificationButtonWrapper = styled.div`
  display: flex;
  gap: 5px;
  margin-top: 8px;
`;
