import React, { useCallback } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Stack from "@mui/material/Stack";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import moment from "moment";
import { TextField } from "@mui/material";

export default function EventDate(props) {
  const handleFunction = useCallback(
    (newValue) => {
      if (newValue) {
        // Validate the selected year to be within an acceptable range
        const selectedYear = moment(newValue).year();
        const currentYear = moment().year();
        const minYear = currentYear - 10; // Accept dates from 10 years ago
        const maxYear = currentYear + 10; // Accept dates from the current year up to 10 years in the future

        if (selectedYear < minYear || selectedYear > maxYear) {
          // If the year is not within the acceptable range, consider the date as invalid
          props.set({ [props.name]: newValue });
        } else {
          props.set({
            [props.name]: moment(newValue).format(
              props?.format || "YYYY-MM-DD"
            ),
          });
        }
      } else {
        props.set({ [props.name]: newValue });
      }
    },
    // eslint-disable-next-line
    [props.set, props.name]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack sx={{ p: 1, ml: 0, mr: 0 }}>
        <DatePicker
          label={props.label || "Event Start Date"}
          value={props.value}
          onChange={handleFunction}
          InputAdornmentProps={{ position: "start" }}
          components={{
            OpenPickerIcon: () => <CalendarMonthIcon className="tw-ml-2" />,
          }}
          renderInput={(params) => <TextField {...params} size="small" />}
        />
      </Stack>
    </LocalizationProvider>
  );
}
