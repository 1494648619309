import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getEventOptions } from "../../services/EventService";
import { getKeyValue } from "../../helper";
import moment from "moment";
import { get } from "../../services/ServicesApi";
import { getDeletedContracts } from "../../services/ContractServices";

const initialState = {
  Data: [],
  isLoading: true,
  EventData: [],
  DeletedEventData: [],
  ParentEventData: [],
  ClientData: [],
  ClientType: [],
  AllCsvData: [],
  EmployeeList: [],
  EventOptions: [],
  SCH_REG: [],
  trigger: true,
};

export const GetData = createAsyncThunk("GetData", async (query) => {
  const result = await get(`/v1/${query}`);
  return result;
});

export const getEventOptionsThunk = createAsyncThunk(
  "GetOptions",
  async (query) => {
    const result = await getEventOptions();
    return result;
  }
);

export const GetEventData = createAsyncThunk(
  "GetEventData",
  async ({ filter }) => {
    const data = {
      shouldLoadEvents: true,
      shouldLoadClients: true,
      limit: filter?.limit,
      page: filter?.currentPage,
    };
    const url = `/v1/event/`;
    const filterData = {};

    Object.entries(filter).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        filterData[key] = value.join(",");
      } else if (value) {
        filterData[key] = value;
      } else if (key === "hasTitle") {
        filterData[key] = value;
      }
    });

    const filteredData = Object.keys(filterData)
      .filter(
        (key) =>
          (filterData?.[key] !== undefined &&
            filterData?.[key] !== null &&
            filterData?.[key] !== "") ||
          key === "hasTitle"
      )
      .reduce((obj, key) => {
        obj[key] = filterData[key];
        return obj;
      }, {});

    const response = await get(url, {
      params: { ...data, ...filteredData },
    });
    return response;
  }
);

export const GetDeletedEvent = createAsyncThunk(
  "GetDeletedEvent",
  async ({ filter }) => {
    const data = {
      shouldLoadEvents: true,
      shouldLoadClients: true,
      limit: filter?.limit,
      page: filter?.currentPage,
    };
    const filterData = {};

    Object.entries(filter).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        filterData[key] = value.join(",");
      } else if (value) {
        filterData[key] = value;
      } else if (key === "hasTitle") {
        filterData[key] = value;
      }
    });

    const filteredData = Object.keys(filterData)
      .filter(
        (key) =>
          (filterData?.[key] !== undefined &&
            filterData?.[key] !== null &&
            filterData?.[key] !== "") ||
          key === "hasTitle"
      )
      .reduce((obj, key) => {
        obj[key] = filterData[key];
        return obj;
      }, {});

    const response = await getDeletedContracts({ ...data, ...filteredData });
    return response;
  }
);

export const GetClientData = createAsyncThunk("GetClientData", async () => {
  const result = await get(`/v1/client/`);
  return result;
});
export const GetClientType = createAsyncThunk("GetClientType", async () => {
  const result = await get(`/v1/client-type/`);
  return result;
});

export const GetAllCsvData = createAsyncThunk(
  "GetAllCsvData",
  async ({ filter } = { filter: {} }) => {
    // Set a default empty object for filter
    const data = {
      shouldLoadEvents: true,
      shouldLoadClients: true,
    };

    const filterData = {};

    // Check if filter is provided and is an object
    if (filter && typeof filter === "object") {
      Object.entries(filter).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          filterData[key] = value.join(",");
        } else if (value) {
          filterData[key] = value;
        } else if (key === "hasTitle") {
          filterData[key] = value; // Keep hasTitle if it exists
        }
      });
    }

    // Build filteredData object
    const filteredData = Object.keys(filterData)
      .filter(
        (key) =>
          (filterData[key] !== undefined &&
            filterData[key] !== null &&
            filterData[key] !== "") ||
          key === "hasTitle"
      )
      .reduce((obj, key) => {
        obj[key] = filterData[key];
        return obj;
      }, {});

    const result = await get(`/v1/event/export-csv`, {
      params: { ...data, ...filteredData },
    });
    return result;
  }
);

export const GetEmployees = createAsyncThunk("GetEmployees", async () => {
  const result = await get(`/v1/employee`);
  return result;
});
export const GetSCH_REG = createAsyncThunk("GetSCH_REG", async () => {
  const result = await get(`/v1/event/regionSchoolOptions`);
  return result;
});

const EventDataReducer = createSlice({
  name: "github",
  initialState,
  reducers: {
    toggleTrigger: (state, action) => {
      state.trigger = !state.trigger;
      return state;
    },
  },
  extraReducers: {
    [GetData.fulfilled]: (state, action) => {
      const data = action.payload;
      state.Data = data;
      return state;
    },
    [GetEventData.pending]: (state, action) => {
      state.isLoading = true;
    },
    [GetEventData.fulfilled]: (state, action) => {
      const data = action.payload;
      if (action?.meta?.arg?.filter?.hasTitle) {
        return {
          ...state,
          ParentEventData: data,
          isLoading: false,
        };
      }
      return {
        ...state,
        EventData: data,
        isLoading: false,
      };
    },
    [GetDeletedEvent.fulfilled]: (state, action) => {
      const data = action.payload;
      return {
        ...state,
        DeletedEventData: data,
        isLoading: false,
      };
    },
    [GetClientData.fulfilled]: (state, action) => {
      const data = action.payload;
      state.ClientData = data;
      return state;
    },
    [getEventOptionsThunk.fulfilled]: (state, action) => {
      const data = action.payload;
      state.EventOptions = data?.data.map((summary) => {
        const { id, startDate } = summary || {};
        const eDate = startDate && moment(startDate).format("MM.DD.YYYY");
        const eventDate = eDate === "Invalid date" || !eDate ? "TBD" : eDate;

        return {
          id,
          name: `${getKeyValue("Client Name", 0, summary)} - ${eventDate}`,
        };
      });
      return state;
    },
    [GetEventData.rejected]: (state, action) => {
      state.isLoading = false;
      return state;
    },
    [GetClientType.fulfilled]: (state, action) => {
      const data = action.payload;
      state.ClientType = data;
      return state;
    },
    [GetAllCsvData.fulfilled]: (state, action) => {
      const data = action.payload;
      state.AllCsvData = data;
      return state;
    },
    [GetEmployees.fulfilled]: (state, action) => {
      const data = action.payload;
      state.EmployeeList = data;
      return state;
    },
    [GetSCH_REG.fulfilled]: (state, action) => {
      const data = action.payload;
      state.SCH_REG = data;
      return state;
    },
  },
});
export const { toggleTrigger } = EventDataReducer.actions;
export default EventDataReducer.reducer;
