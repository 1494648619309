import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { resetPassword } from "../../services/AuthServices";
import { generateToast } from "../../helper";
import { ERROR, TOP_RIGHT } from "../../constant";
import { useTheme } from "../../ThemeContext";
import Message from "../../components/Message/Message";

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [inputs, setInputs] = useState({
    password: "",
    confirmPassword: "",
  });
  const { token } = useParams();
  const history = useNavigate();
  const [showConfPassword, setShowConfPassword] = useState(false);
  const [messageDetail, setMessageDetail] = useState(null);

  const themeToggle = useTheme();
  const { mode } = themeToggle.themeState;

  const { password, confirmPassword } = inputs;

  const onChange = (e) =>
    setInputs({ ...inputs, [e.target.name]: e.target.value });

  const onFinish = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      return generateToast("Password not match", ERROR, TOP_RIGHT);
    }
    setisLoading(true);
    const resetPromise = resetPassword(token, { password, confirmPassword });

    resetPromise
      .then((response) => {
        setisLoading(false);
        setMessageDetail({
          title: "Success",
          message: "Password Reset!",
        });
        handleRedirect();
        return response;
      })
      .catch((err) => {
        setisLoading(false);
        setMessageDetail({
          title: "Error",
          message: err?.data?.error || "Error!, Please try again later",
        });
      });
  };

  const handleRedirect = () => {
    history("/login");
  };

  return (
    <>
      <div
        className={`login-screen-wrapper tw-bg-fixed tw-pt-[136px] tw-w-full tw-h-screen ${
          mode || ""
        }`}
      >
        <div className="tw-rounded-xl tw-bg-gray-100 dark:tw-bg-[#151515] tw-w-[437px] tw-h-auto tw-py-[35px] tw-mx-auto">
          <div className="tw-flex tw-w-full tw-px-10 tw-flex-col tw-items-center tw-justify-center">
            <img
              src="../images/headerlogo.png"
              className="tw-h-16 tw-text-center"
              alt="Logo"
            />
            <h1 className="tw-text-2xl tw-font-poppins dark:tw-text-white tw-leading-9 tw-text-darkGrey tw-mt-4 tw-font-[600] tw-capitalize">
              Reset Passwords
            </h1>
            <p className="tw-text-base  dark:tw-text-white tw-leading-9 tw-text-darkGrey">
              Please set your new password to proceed.
            </p>
            <form onSubmit={onFinish} className="tw-w-full tw-mt-3">
              <div className="tw-text-md tw-w-full">
                <label className="tw-w-full tw-inline-flex tw-items-center tw-relative">
                  <i className="fa-solid tw-text-lightGrey tw-text-xl fa-lock tw-absolute tw-inset-y-2/2 tw-top-5 tw-left-3"></i>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    className="tw-leading-5  tw-w-full dark:tw-text-white tw-mt-3 tw-bg-transparent tw-border tw-border-gray-300 tw-text-gray-900 tw-rounded-lg tw-focus:tw-ring-blue-500 tw-focus:tw-border-blue-500 tw-block tw-py-2.5 tw-px-10"
                    placeholder="New Password"
                    required
                    name="password"
                    onChange={(e) => onChange(e)}
                  />
                  {showPassword ? (
                    <i
                      onClick={() => setShowPassword(!showPassword)}
                      className="tw-pointer fa-solid tw-text-lightGrey tw-text-md fa-eye tw-absolute tw-inset-y-2/2 tw-top-7 tw-right-3"
                    ></i>
                  ) : (
                    <i
                      onClick={() => setShowPassword(!showPassword)}
                      className="tw-pointer fa-solid tw-text-lightGrey tw-text-md fa-eye-slash tw-absolute tw-inset-y-2/2 tw-top-7 tw-right-3"
                    ></i>
                  )}
                </label>
                <label className="tw-w-full tw-inline-flex tw-items-center tw-relative">
                  <i className="fa-solid tw-text-lightGrey tw-text-xl fa-lock tw-absolute tw-inset-y-2/2 tw-top-5 tw-left-3"></i>
                  <input
                    type={showConfPassword ? "text" : "password"}
                    id="password_confirm"
                    className="tw-leading-5  tw-w-full dark:tw-text-white tw-mt-3 tw-bg-transparent tw-border tw-border-gray-300 tw-text-gray-900 tw-rounded-lg tw-focus:tw-ring-blue-500 tw-focus:tw-border-blue-500 tw-block tw-py-2.5 tw-px-10"
                    placeholder="Confirm Password"
                    required
                    name="confirmPassword"
                    onChange={(e) => onChange(e)}
                  />
                  {showConfPassword ? (
                    <i
                      onClick={() => setShowConfPassword(!showConfPassword)}
                      className="tw-pointer fa-solid tw-text-lightGrey tw-text-md fa-eye tw-absolute tw-inset-y-2/2 tw-top-7 tw-right-3"
                    ></i>
                  ) : (
                    <i
                      onClick={() => setShowConfPassword(!showConfPassword)}
                      className="tw-pointer fa-solid tw-text-lightGrey tw-text-md fa-eye-slash tw-absolute tw-inset-y-2/2 tw-top-7 tw-right-3"
                    ></i>
                  )}
                </label>
              </div>
              <button
                className="tw-rounded-md tw-text-white tw-text-xl tw-py-2 tw-mt-3 tw-w-full tw-border-0 tw-bg-blue"
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "Reset"}
              </button>
            </form>
          </div>
        </div>
        <Message messageDetail={messageDetail} />
      </div>
    </>
  );
};

export default ResetPassword;
