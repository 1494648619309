import React, { useContext, useMemo } from "react";
import { AppContext } from "../../provider/AppContext";
import AAvatar from "../../atoms/MUI/Avatar";
import { useTheme } from "../../ThemeContext";
import "react-color-palette/lib/css/styles.css";
import { NotificationWrapper } from "../../styled";
import { calculateActiveStatus } from "../../helper";
import moment from "moment";
import ActiveStatus from "./ActiveUser";

const calculateSortingLogic = (activeUsers) => {
  // Separate the users with logoutTime from those without
  const usersWithLogoutTime = activeUsers.filter((user) => user.logoutTime);
  const usersWithoutLogoutTime = activeUsers.filter((user) => !user.logoutTime);

  // Sort users with logoutTime by logoutTime (descending order)
  usersWithLogoutTime?.sort((a, b) => {
    const logoutTimeA = moment(a.logoutTime);
    const logoutTimeB = moment(b.logoutTime);
    return logoutTimeB.diff(logoutTimeA);
  });

  // Sort users without logoutTime by loginTime (descending order)
  usersWithoutLogoutTime?.sort((a, b) => {
    const logoutTimeA = moment(a.loginTime);
    const logoutTimeB = moment(b.loginTime);
    return logoutTimeB.diff(logoutTimeA);
  });

  // Concatenate the sorted arrays to get the final sorted array
  const sortedUsers = [...usersWithoutLogoutTime, ...usersWithLogoutTime];

  return sortedUsers;
};

export const ActiveUsers = ({ activeModal, setActiveModal }) => {
  const { activeUsers } = useContext(AppContext);

  const themeToggle = useTheme();
  const { backgroundThemeColor, textThemeColor, themeCardBackground } =
    themeToggle.themeState;

  return (
    <NotificationWrapper
      textThemeColor={textThemeColor}
      backgroundThemeColor={backgroundThemeColor}
      themeCardBackground={themeCardBackground}
      customBurgerIcon={false}
      isOpen={activeModal}
      onClose={() => {
        setActiveModal(false);
      }}
      right
      width={"600px"}
    >
      <h2>Active Users</h2>

      <div className="notification-content">
        {activeUsers?.map((employee, i) => {
          // Check if nestedArray exists and is an array
          const hasNestedArray =
            Array.isArray(employee) && employee?.length > 0;
          const sortedActiveUsers = hasNestedArray
            ? calculateSortingLogic(employee)
            : [];

          return (
            <div key={i}>
              {hasNestedArray ? (
                <>
                  {sortedActiveUsers.map((user, j) => (
                    <div key={j} className="notification-card">
                      <AAvatar
                        active={!user.logoutTime}
                        userProfile={user.employee}
                      />
                      <div>
                        <span className="user-text">{`${
                          user?.employee?.firstName || "Anonymous"
                        } ${user?.employee?.lastName || "User"}`}</span>
                        <ActiveStatus {...user} />
                      </div>
                    </div>
                  ))}
                </>
              ) : null}
            </div>
          );
        })}
      </div>
    </NotificationWrapper>
  );
};

