import styled from "styled-components";
import { Button, Input, Select } from "antd";

export const Wrapper = styled.div`
  width: ${({ width }) => width || "878px"};
  padding: ${({ padding }) => padding || "10px"};
  background-color: #ffffff;
  color: black;
  margin: auto;
  border-radius: 10px;
  overflow: auto;
  margin-top: 120px;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.24);

  .ant-select-selector {
    height: 100% !important ;
  }

  h2 {
    text-align: center;
    font-weight: 600;
    font-size: 31px;
    margin: 20px 20px 40px 20px;
  }
  .input_wrapper {
    width: 90%;
    margin: auto;
  }
  .ant-form-item-feedback-icon {
    display: flex;
  }

  .ant-form-item-explain-error {
    font-weight: 500 !important;
  }

  .text-right {
    display: flex;
    justify-content: flex-end;
    margin-top: 70px;
  }
  .ant-form-vertical .ant-form-item-label > label,
  .ant-form-item-required {
    font-weight: 900;
    font-size: 15px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input:placeholder-shown {
    background: white;
    color: black;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 54px;
    border-radius: 8px;
  }
  h3 {
    text-align: center;
    padding: 10px;
    font-weight: 900;
    text-transform: none;
    color: black;
  }

  ${({ theme }) => {
    return (
      theme.mode === "dark" &&
      `
    background-color: #353a3f;
    .ant-form-vertical .ant-form-item-label > label,
    .ant-form-item-required {
      color: #E6E6E6;
    }
    h3{
      color:#E6E6E6
    }
    .ant-select-selector,
    .ant-select-selection-item,
    .hCiZQW,
    .ant-input,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector,
    .ant-input:placeholder-shown {
     background: #353a3f;
     color: #E6E6E6;
    }
    `
    );
  }}
`;
export const TableWrapper = styled.table`
  width: ${({ width }) => width || "878px"};
  padding: ${({ padding }) => padding || "10px"};
  background-color: #ffffff;
  color: black;
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.24);
  ${({ theme }) => {
    return (
      theme.mode === "dark" &&
      `
     background-color: #353a3f;
    
    `
    );
  }}
`;

export const CustomPasswordInput = styled(Input.Password)`
  border: 1px solid #d7d7d7 !important;
  box-sizing: border-box !important;
  border-radius: 8px !important;
  font-style: normal;
  height: 57px !important;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  margin: ${({ margin }) => margin} !important;
  letter-spacing: -0.02em;
  /* Secondary */
  input {
    background-color: ${({ background }) => background || "#ffffff"} !important;
    color: ${({ color }) => color || "black"} !important;
  }
`;

export const CustomInput = styled(Input)`
  /* background: #ffffff !important; */
  border: 1px solid #d7d7d7 !important;
  box-sizing: border-box !important;
  border-radius: 8px !important;
  font-style: normal;
  height: 48px !important;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  margin: ${({ margin }) => margin} !important;
  /* identical to box height */

  letter-spacing: -0.02em;

  /* Secondary */

  color: #303030;
`;

export const CustomSelect = styled(Select)`
  border: 1px solid #d7d7d7 !important;
  box-sizing: border-box !important;
  border-radius: 8px !important;
  font-style: normal;
  height: 48px !important;
  .ant-select-selection-placeholder {
    display: flex;
    align-items: center !important;
  }
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  margin: ${({ margin }) => margin} !important;
  /* identical to box height */

  letter-spacing: -0.02em;

  /* Secondary */

  /* color: #303030; */
  .ant-select-selector {
    /* background: #ffffff !important; */
    border: 1px solid #d7d7d7 !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
    font-style: normal;
    /* height: 57px !important; */
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    margin: ${({ margin }) => margin} !important;
    /* identical to box height */

    letter-spacing: -0.02em;

    /* Secondary */

    /* color: #303030; */

    .ant-select-selection-item {
      display: flex;
      align-items: center;
      font-size: 15px;
      line-height: 18px;
    }
  }
`;

export const ButtonWrapper = styled(Button)`
  height: ${({ height }) => height || "54px"} !important;
  width: ${({ width }) => width} !important;
  display: ${({ display }) => display} !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: ${({ textAlign }) => textAlign};
  border-radius: ${({ borderRadius }) => borderRadius || "100px"} !important;

  /* Primary */
  :hover,
  :focus {
    background: ${({ background }) => background} !important;
    color: ${({ primary }) => (!primary ? "#FFFFFF" : "#303030")} !important;
  }
  background: ${({ background }) => background} !important;

  box-shadow: ${({ shadow }) => shadow};
  font-style: normal;
  font-weight: 600 !important;
  border: ${({ border }) => border || "none"} !important;
  font-size: ${({ fontSize }) => fontSize || "17px"} !important;
  /* padding: ${({ padding }) => padding || "17px 22px"} !important; */
  line-height: 20px !important;
  /* identical to box height */

  letter-spacing: -0.02em !important;
  margin: ${({ margin }) => margin} !important;
  margin-top: ${({ marginTop }) => marginTop};
  gap: ${({ gap }) => gap} !important;

  /* White */

  color: ${({ primary }) => (!primary ? "#FFFFFF" : "#303030")} !important;

  ${({ color }) =>
    color &&
    `
    color: ${color} !important;
    :hover,
    :focus {
      color: ${color} !important;
    }
  `}
`;
