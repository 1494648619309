import React from "react";
import { TableCell } from "@mui/material";

const TableCellWrapper = ({
  children,
  className,
  extraOption,
  onClick,
  isHidden,
}) => {
  if (isHidden) return;

  return (
    <TableCell
      className={`custom-header-tablecell tw-z-30 tw-sticky tw-bg-customGray tw-top-0 ${className}`}
    >
      <div className={"custom-header-container !tw-pb-1"}>
        <p
          className={
            "custom-header-title tw-flex tw-gap-2 tw-justify-center tw-whitespace-nowrap"
          }
          onClick={onClick}
        >
          {children}
        </p>
        <span className="!tw-normal-case">{extraOption}</span>
      </div>
    </TableCell>
  );
};

export default TableCellWrapper;
