import { FormControl, TextField } from "@mui/material";
import { amountFormatter, convertToNumber } from "../../helper";

export default function ANumberInput(props) {
  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };
  const type = props.isAmount ? "text" : "number";
  const inputProps = props.isAmount
    ? {
        inputProps: { min: 0 },
        inputMode: "numeric",
        lang: "en-US",
      }
    : { min: 0 };
  const value = props.isAmount ? amountFormatter(props.value) : props.value;

  return (
    <FormControl sx={props.sx_form || { m: 1 }}>
      <TextField
        disabled={props.disabled}
        sx={props.sx}
        size="small"
        type={type}
        id="outlined-basic"
        InputProps={inputProps}
        onKeyPress={props.onKeyPress || preventMinus}
        label={props.label}
        variant="outlined"
        placeholder={props.placeholder}
        value={value}
        onChange={(e) =>
          props.set({
            [props.name]: props.isAmount
              ? convertToNumber(e.target.value)
              : e.target.value,
          })
        }
      />
    </FormControl>
  );
}
