import * as React from "react";
import { CursorWrapper } from "./styles";
import { usePerfectCursor } from "./UsePerfectCursor";

export const Cursor = ({ point, color, text }) => {
  const rCursor = React.useRef(null);

  const animateCursor = React.useCallback((point) => {
    const elm = rCursor.current;
    if (!elm) return;
    elm.style.setProperty(
      "transform",
      `translate(${point[0] - 4}px, ${point[1] - 32}px)`
    );
  }, []);

  const onPointMove = usePerfectCursor(animateCursor);
  // Update the point whenever the component updates
  onPointMove(point);

  return (
    <CursorWrapper text={text} color={color} ref={rCursor}>
      <svg
        width="24"
        height="24"
        stroke-width="1.5"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.5027 9.96958C20.7073 10.4588 20.6154 12.1941 19.3658 12.5533L13.0605 14.3658L10.1807 20.2606C9.60996 21.4288 7.88499 21.218 7.6124 19.9468L4.67677 6.25646C4.44638 5.18204 5.5121 4.2878 6.53019 4.70126L19.5027 9.96958Z"
          stroke="white"
          fill={color}
          stroke-width="1.5"
        />{" "}
      </svg>
    </CursorWrapper>
  );
};
