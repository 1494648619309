import { get, patch, post } from "./ServicesApi";

const createClient = (body) => {
  const clientPromise = post("/v1/client", body);
  return clientPromise;
};

const editClientData = (id, body) => {
  const clientPromise = patch(`/v1/client/${id}`, body);
  return clientPromise;
};

const createClientType = (body) => {
  const clientTypePromise = post("/v1/client-type", body);
  return clientTypePromise;
};

const updateClientTypeData = (id, body) => {
  const clientTypePromise = patch(`/v1/client-type/${id}`, body);
  return clientTypePromise;
};

const getClients = (body) => {
  const clientPromise = get("/v1/client", body);
  return clientPromise;
};

const updateMultiClients = (body) => {
  const clientPromise = patch(`/v1/client/multi-update/`, body);
  return clientPromise;
};

export {
  getClients,
  createClient,
  createClientType,
  editClientData,
  updateMultiClients,
  updateClientTypeData,
};
