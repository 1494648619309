import React from "react";
import { useTheme } from "../../ThemeContext";

const MinMaxInput = ({ valueMin, valueMax, onChangeMin, onChangeMax }) => {
  const themeToggle = useTheme();
  const mode = themeToggle.themeState?.mode;

  return (
    <div className="tw-w-24 tw-flex tw-h-full">
      <input
        type="number"
        placeholder="min"
        value={valueMin || ""}
        onChange={onChangeMin}
        className={`min-input ${mode === "dark" && "backgroundBasic"}`}
      />
      <input
        type="number"
        placeholder="max"
        value={valueMax || ""}
        onChange={onChangeMax}
        className={`max-input ${mode === "dark" && "backgroundBasic"}`}
      />
    </div>
  );
};

export default MinMaxInput;
