import React, { useMemo } from "react";
import { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { Wrapper } from "./styled";

const ThemeToggleContext = React.createContext();

export const useTheme = () => React.useContext(ThemeToggleContext);

const COLOR_PATTENS = {
  backgroundThemeColor: "#a5cfe6",
  textThemeColor: "#121212",
  themeCardBackground: "#65AED7",
  themeContentColor: "#121212",
  backgroundRGBA:
    "rgba(165, 207, 230, 0.4) 2px 2px, rgba(165, 207, 230, 0.3) 3px 3px, rgba(165, 207, 230, 0.2) 4px 4px",
};

export const MyThemeProvider = ({ children }) => {
  const [themeState, setThemeState] = React.useState({
    mode: localStorage.getItem("mode") || "light",
  });

  const toggle = () => {
    const mode = themeState.mode === "light" ? `dark` : `light`;
    localStorage.setItem("mode", mode);
    document.documentElement.setAttribute("data-theme", mode);

    setThemeState({
      ...themeState,
      mode: mode,
    });
  };

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", themeState.mode);
    // eslint-disable-next-line
  }, []);

  const Memoized = useMemo(() => {
    return <>{children}</>;
    // eslint-disable-next-line
  }, []);

  return (
    <ThemeToggleContext.Provider
      value={{ toggle: toggle, themeState, setThemeState }}
    >
      <ThemeProvider
        theme={{
          mode: themeState.mode,
        }}
      >
        <Wrapper
          backgroundThemeColor={COLOR_PATTENS?.backgroundThemeColor}
          textThemeColor={COLOR_PATTENS?.textThemeColor}
          themeCardBackground={COLOR_PATTENS?.themeCardBackground}
          themeContentColor={COLOR_PATTENS?.themeContentColor}
          backgroundRGBA={COLOR_PATTENS?.backgroundRGBA}
        >
          {Memoized}
        </Wrapper>
      </ThemeProvider>
    </ThemeToggleContext.Provider>
  );
};

export default ThemeProvider;
