import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getContracts, getGraphStats, getResourceStats } from "../../services/ContractServices";

const initialState = {
  Data: [],
  isLoading: true,
  StatisticsOne: null,
  StatisticsTwo: null,
  GraphsData: null,
  ResourcesData: null,
};

export const getStatisticsOne = createAsyncThunk("GetStatsOne", getContracts);
export const getStatisticsTwo = createAsyncThunk("GetStatsTwo", getContracts);
export const getGraphs = createAsyncThunk("getGraphStats", getGraphStats);
export const getResources = createAsyncThunk("getResourceStats", getResourceStats);
export const getStatisticsThree = createAsyncThunk("GetStatsThree", getContracts);

const EventDataReducer = createSlice({
  name: "statistics",
  initialState,
  reducers: {},
  extraReducers: {
    [getStatisticsOne.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getStatisticsOne.fulfilled]: (state, action) => {
      const data = action.payload;
      state.StatisticsOne = data;
      state.isLoading = false;
      return state;
    },
    [getStatisticsOne.rejected]: (state, action) => {
      state.isLoading = false;
      return state;
    },
    [getStatisticsTwo.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getStatisticsTwo.fulfilled]: (state, action) => {
      const data = action.payload;
      state.StatisticsTwo = data;
      state.isLoading = false;
      return state;
    },
    [getStatisticsTwo.rejected]: (state, action) => {
      state.isLoading = false;
      return state;
    },
    [getStatisticsThree.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getStatisticsThree.fulfilled]: (state, action) => {
      const data = action.payload;
      state.StatisticsThree = data;
      state.isLoading = false;
      return state;
    },
    [getStatisticsThree.rejected]: (state, action) => {
      state.isLoading = false;
      return state;
    },
    [getGraphs.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getGraphs.fulfilled]: (state, action) => {
      const data = action.payload;
      state.GraphsData = data.monthlySumsByUser;
      state.isLoading = false;
      return state;
    },
    [getGraphs.rejected]: (state, action) => {
      state.isLoading = false;
      return state;
    },
    [getResources.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getResources.fulfilled]: (state, action) => {
      const data = action.payload;
      state.ResourcesData = data.data;
      state.isLoading = false;
      return state;
    },
    [getResources.rejected]: (state, action) => {
      state.isLoading = false;
      return state;
    },
  },
});

export default EventDataReducer.reducer;
