import React from "react";
import { useState } from "react";
import { forgetPassword } from "../../services/AuthServices";
import { useTheme } from "../../ThemeContext";
import Message from "../../components/Message/Message";

const ForgotPassword = () => {
  const [isLoading, setisLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [messageDetail, setMessageDetail] = useState(null);

  const themeToggle = useTheme();
  const { mode } = themeToggle.themeState;

  const onChange = (e) => setEmail(e.target.value);

  const onFinish = (e) => {
    e.preventDefault();
    setisLoading(true);
    const forgetPromise = forgetPassword({ email: email });

    forgetPromise
      .then(() => {
        setisLoading(false);
        setIsEmailSent(true);
        setMessageDetail({
          title: "Success",
          message: "Password Reset link sent to your registered email",
        });
      })
      .catch((err) => {
        setIsEmailSent(false);
        setisLoading(false);
        setMessageDetail({
          title: "Error",
          message: err?.data?.message || "Error!, Please try again later",
        });
      });
  };

  return (
    <>
      <div
        className={`login-screen-wrapper tw-bg-fixed tw-pt-[136px] tw-w-full tw-h-screen ${
          mode || ""
        }`}
      >
        {!isEmailSent && (
          <div className="tw-rounded-xl tw-bg-gray-100 tw-dark:bg-[#151515] tw-w-[437px] tw-h-auto tw-py-[35px] tw-mx-auto">
            <div className="tw-flex tw-w-full tw-px-10 tw-flex-col tw-items-center tw-justify-center">
              <img
                src="../images/headerlogo.png"
                className="tw-h-16 tw-text-center"
                alt="Icon"
              />
              <h1 className="tw-text-2xl tw-font-poppins tw-dark:text-white tw-leading-9 tw-text-darkGrey tw-mt-4 tw-font-[600] tw-capitalize">
                Forgot Password?
              </h1>
              <p className="tw-text-lg tw-text-center tw-dark:text-white tw-leading-9 tw-text-darkGrey">
                <i>
                  <b>Don’t worry, we got your back.</b>
                </i>{" "}
                <br />
                Enter the email address associated with this account.
              </p>
              <form
                name="normal_login"
                onSubmit={onFinish}
                className="tw-w-full tw-mt-3"
              >
                <div className="tw-text-md tw-w-full">
                  <label className="tw-w-full tw-inline-flex tw-items-center tw-relative">
                    <i className="fa-solid tw-text-lightGrey tw-text-xl fa-envelope tw-absolute tw-inset-y-2/2 tw-top-5 tw-left-3"></i>

                    <input
                      type="email"
                      id="email"
                      className="tw-leading-5 tw-w-full tw-dark:text-white tw-mt-3 tw-bg-transparent tw-border tw-border-gray-300 tw-text-gray-900 tw-rounded-lg tw-focus:tw-ring-blue-500 tw-focus:tw-border-blue-500 tw-block tw-py-2.5 tw-px-10"
                      placeholder="Email"
                      required
                      name="email"
                      onChange={(e) => onChange(e)}
                    />
                  </label>
                </div>
                <button
                  type="submit"
                  className="tw-rounded-md tw-text-white tw-text-xl tw-py-2 tw-mt-3 tw-w-full tw-border-0 tw-bg-blue"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        )}
        {isEmailSent && (
          <div className="tw-rounded-xl tw-bg-gray-100 tw-dark:bg-[#151515] tw-w-[437px] tw-h-auto tw-py-[35px] tw-mx-auto">
            <div className="tw-flex tw-w-full tw-px-10 tw-flex-col tw-items-center tw-justify-center">
              <img
                src="../images/headerlogo.png"
                className="tw-h-16 tw-text-center"
                alt="Logo"
              />
              <h1 className="tw-text-2xl tw-font-poppins tw-dark:text-white tw-leading-9 tw-text-darkGrey tw-mt-4 tw-font-[600] tw-capitalize">
                Email Sent
              </h1>
              <p className="tw-text-lg tw-text-center tw-dark:text-white tw-text-darkGrey">
                Link has been sent to email <i>{email}. </i>
                Please open the mail to rest your password.
              </p>
              <button
                href="#"
                className="tw-text-lightBlue tw-text-lg tw-mt-3 tw-underline"
                onClick={() => {
                  setisLoading(true);
                  const forgetPromise = forgetPassword({ email: email });

                  forgetPromise
                    .then(() => {
                      setisLoading(false);
                      setMessageDetail({
                        title: "Success",
                        message:
                          "Password Reset link sent to your registered email",
                      });
                    })
                    .catch((err) => {
                      setisLoading(false);
                      setMessageDetail({
                        title: "Error",
                        message:
                          err?.data?.error || "Error!, Please try again later",
                      });
                    });
                }}
              >
                {isLoading ? "Resending..." : "Resend email"}
              </button>
            </div>
          </div>
        )}
        <Message messageDetail={messageDetail} />
      </div>
    </>
  );
};

export default ForgotPassword;
