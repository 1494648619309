import * as React from "react";
import Avatar from "@mui/material/Avatar";
import { useContext } from "react";
import { AppContext } from "../../provider/AppContext";
import { Tooltip } from "antd";
import { Badge, styled } from "@mui/material";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      height: 30,
      width: 30,
      fontSize: 14
    },
    children: `${name.split(" ")?.[0]?.[0]}${name.split(" ")?.[1]?.[0]}`,
  };
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export default function AAvatar({ userProfile, active }) {
  const { user } = useContext(AppContext);
  const { firstName, lastName } = userProfile || user || {};
  const fullNameString = String(firstName) + " " + String(lastName) || "";

  return (
    <div className="avatar-div">
      <StyledBadge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        variant={active ? "dot" : ""}
      >
        <Tooltip title={fullNameString}>
          <Avatar {...stringAvatar(fullNameString)} />
        </Tooltip>
      </StyledBadge>
    </div>
  );
}
