import { get, patch, post } from "./ServicesApi";

const createVenue = (body) => {
  const venuePromise = post("/v1/venue", body);
  return venuePromise;
};

const editVenueData = (id, body) => {
  const venuePromise = patch(`/v1/venue/${id}`, body);
  return venuePromise;
};

const getVenueData = () => {
  const venuePromise = get(`/v1/venue`);
  return venuePromise;
};

export { createVenue, editVenueData, getVenueData };
