import { get, patch, post, submitFormData } from "./ServicesApi";

const resetPassword = (token, body) => {
  const resetPromise = patch(`/v1/auth/reset-password/${token}`, body);
  return resetPromise;
};

const loginUser = (body) => {
  const loginPromise = post("/v1/auth/login", body);
  return loginPromise;
};

const logoutUser = (body) => {
  const loginPromise = get("/v1/auth/logout", body);
  return loginPromise;
};

const forgetPassword = (body) => {
  const forgetPromise = patch("/v1/auth/forgot-password", body);
  return forgetPromise;
};

const signUpUser = (body) => {
  const signUpPromise = post("/v1/auth/signup", body);
  return signUpPromise;
};

const updatePassword = (body) => {
  const changePasswordPromise = patch("/v1/auth/change-password", body);
  return changePasswordPromise;
};

const updateProfile = (body) => {
  const updateProfilePromise = patch("/v1/employee", body);
  return updateProfilePromise;
};

const updateEmpProfile = (id, body) => {
  const updateProfilePromise = patch(`/v1/employee/${id}`, body);
  return updateProfilePromise;
};

const verifyProfile = (body) => {
  const updateProfilePromise = post("/v1/verify", body);
  return updateProfilePromise;
};

const support = (body) => {
  return submitFormData("/v1/support/ticket", body);
};

export {
  resetPassword,
  loginUser,
  updateEmpProfile,
  forgetPassword,
  signUpUser,
  support,
  logoutUser,
  verifyProfile,
  updatePassword,
  updateProfile,
};
