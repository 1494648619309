import { useState, useEffect } from "react";
import { set, get } from "./idb.js";

export const useIdb = (key, initialState) => {
  const [item, setItem] = useState(initialState);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    get(key)
      .then((value) => value === undefined || setItem(value))
      .finally(() => setLoading(false));
  }, [key]);

  return [
    item,
    (value) => {
      setItem(value);
      return set(key, value);
    },
    isLoading,
  ];
};
