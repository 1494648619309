import { DarkModeSwitch } from "react-toggle-dark-mode";

import { useTheme } from "../ThemeContext";

// Dark/Light Mode Switch For Navbar
export const DarkMode = () => {
  const themeToggle = useTheme();

  return (
    <DarkModeSwitch
      // className="tw-mr-4"
      sunColor="#f9d71c"
      animationProperties={defaultProperties}
      checked={themeToggle.themeState?.mode === "dark"}
      onChange={() => themeToggle.toggle()}
      size={25}
    />
  );
};

export const defaultProperties = {
  dark: {
    circle: {
      r: 9,
    },
    mask: {
      cx: "50%",
      cy: "23%",
    },
    svg: {
      transform: "rotate(40deg)",
    },
    lines: {
      opacity: 0,
    },
  },
  light: {
    circle: {
      r: 5,
    },
    mask: {
      cx: "100%",
      cy: "0%",
    },
    svg: {
      transform: "rotate(90deg)",
    },
    lines: {
      opacity: 1,
    },
  },
  springConfig: { mass: 4, tension: 250, friction: 35 },
};
