import React, { Fragment, useContext, useState } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import "../../styles/NavBar.css";
import { AppContext } from "../../provider/AppContext";
import AAvatar from "../../atoms/MUI/Avatar";
import { DarkMode } from "../../styles/ThemePallette";
import "react-color-palette/lib/css/styles.css";
import NotificationComponent, {
  NotificationBadge,
  UserBadge,
} from "../Notifications/Notifications";
import { ActiveUsers } from "../ActiveUsers/ActiveUsers";
import HamburgerMenu from "./HamburgerMenu";

export const executiveRoles = [
  "Admin",
  "Owner",
  "Event Director Lead",
  "Event Director Lead Auditor",
];

const Navbar = (props) => {
  const {
    // user,
    notificationCount,
    getNotifications,
    activeUsers,
    handleReadNotification,
  } = useContext(AppContext);
  // const { id } = user || {};
  const [showNotifications, setNotifications] = useState(false);
  const [activeModal, setActiveModal] = useState(false);

  const navItem = (
    path,
    label,
    className = "nav-link btn",
    index,
    onClick = null
  ) => (
    <li className="nav-item" key={index}>
      {!!props.isAuthenticated && (
        <Link
          className={`nav-link btn !tw-text-sm tw-py-2 ${className}`} // Use the className parameter
          to={onClick ? "#" : path}
          onClick={onClick}
        >
          {label}
        </Link>
      )}
    </li>
  );

  const renderDropdownItems = (items) =>
    items.map((item, index) => (
      <Fragment key={index}>
        <Link className="dropdown-link" to={item.path}>
          {item.label}
        </Link>
      </Fragment>
    ));

  const dropdownForRole = (isAdmin, items) =>
      <Dropdown>
        <Dropdown.Toggle className="owner-dropdown">
          <AAvatar />
          <span className="tw-pl-1.5">Settings</span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="d-flex flex-column">{renderDropdownItems(items)}</div>
        </Dropdown.Menu>
      </Dropdown>

  const adminItems = [
    { path: "/pricing/employee", label: "Pricing +/-" },
    { path: "/password", label: "Change Password" },
    { path: "/profile", label: "Edit Profile" },
    { path: "/createemployee", label: "Add Employee" },
    { path: "/employees", label: "Edit Employee Roles" },
    { path: "/restore-contract", label: "Restore Contract" },
    { path: "/template-contract", label: "Update Template" },
  ];

  const userItems = [
    { path: "/client", label: "Client Profiles +/-" },
    { path: "/password", label: "Change Password" },
    { path: "/profile", label: "Edit Profile" },
    { path: "/restore-contract", label: "Restore Contract" },
    { path: "/template-contract", label: "Update Template" },  ];

  return (
    <Fragment>
      {!!props.isAuthenticated && (
        <div>
          <HamburgerMenu
            showNotifications={showNotifications}
            setNotifications={setNotifications}
            isAdmin={props.isAdmin}
            logout={props.logout}
            adminItems={adminItems}
            userItems={userItems}
            dropdownForRole={dropdownForRole}
            navItem={navItem}
            notificationCount={notificationCount}
            getNotifications={getNotifications}
            handleReadNotification={handleReadNotification}
            activeUsers={activeUsers}
            setActiveModal={setActiveModal}
          />

          <ActiveUsers {...{ activeModal, setActiveModal }} />
          <NotificationComponent {...{ showNotifications, setNotifications }} />
          <nav
            style={{ borderBottom: "1px solid black" }}
            className="navbar navbar-expand-lg navbar-dark"
          >
            <div className="container-fluid">
              <Link to="/home">
                <div className="image-wrapper">
                  <img
                    className="logo-image w-10"
                    height={10}
                    src="../images/headerlogo.png"
                    alt="TPE Logo"
                  />
                </div>
              </Link>
              <ul className="navbar-nav me-auto">
                {/* {navItem(`/contracts/${id}`, "Contracts")} */}
                {/* {navItem(`/stats`, "Stats")} */}
                {navItem(`/contracts`, "Contracts", "nav-item-contracts")}
                {navItem(
                  `/support`,
                  "Support Ticket",
                  "nav-item-support-ticket"
                )}
                {props.isAdmin
                  ? navItem(
                      `/tpe_support`,
                      "Support Progress",
                      "nav-item-support"
                    )
                  : null}
                {navItem("", "Logout", "nav-item-logout", null, (e) => {
                  e.preventDefault();
                  props.logout(e);
                })}
              </ul>

              <div className="navbar-collapse" id="navbarColor02">
                <ul className="navbar-nav me-auto"></ul>
                <div className="d-flex">
                  <NotificationBadge
                    {...{
                      getNotifications,
                      handleReadNotification,
                      setNotifications,
                      notificationCount,
                    }}
                  />
                  <UserBadge {...{ activeUsers, setActiveModal }} />
                </div>
                <div className="nav-link nav-item-color me-4">
                  <DarkMode />
                </div>
                {props.isAdmin
                  ? dropdownForRole(true, adminItems)
                  : dropdownForRole(false, userItems)}
              </div>
            </div>
          </nav>
        </div>
      )}
    </Fragment>
  );
};

export default Navbar;
