import React from "react";
import { Loader } from "rsuite";
const FullPageLoader = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 9999,
        backgroundColor:
          "rgba(0, 0, 0, 0.5)",
        backdropFilter:
          "blur(10px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader size="lg" content="Loading..." />
      </div>
    </div>
  );
};

export default FullPageLoader;
