export const accessTypes = {
  contractManagement: {
    canReconciled: "canReconciled",
    canInvoiced: "canInvoiced",
  },

  // Event Management Access Types
  eventManagement: {
    canEditSoldBy: "canEditSoldBy",
    canEditInvoice: "canEditInvoice",
    canEditStatus: "canEditStatus",
    canEditSignDate: "canEditSignDate",
  },

  //   // Staffing Management Access Types
  //   staffingManagement: {
  //     canAssignStaff: "canAssignStaff",
  //     canRemoveStaff: "canRemoveStaff",
  //     canViewStaffingDetails: "canViewStaffingDetails",
  //   },

  //   // Reporting Access Types
  //   reports: {
  //     canViewReports: "canViewReports",
  //     canGenerateReports: "canGenerateReports",
  //   },
};
