import React from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonLoadingRow = ({ columnCount }) => {
  const skeletonCells = Array.from({ length: columnCount }, (_, index) => (
    <td className="!tw-px-2 !tw-py-1" key={index}>
      <Skeleton className="!tw-h-5" />
    </td>
  ));

  return <tr>{skeletonCells}</tr>;
};

export default SkeletonLoadingRow;
