import { destroy, get, patch, post } from "./ServicesApi";


const createFilterKey = (body) => {
  return post(`/v1/key-store`, body);
};
const getFilterByKey = (key) => {
  return get(`/v1/key-store/${key}`);
};


export {
  createFilterKey,
  getFilterByKey,
};
