import { configureStore } from "@reduxjs/toolkit";
import EventDataReducer from "../reducers/EventReducer";
import Statistics from "../reducers/StatsReducer";
import Notification from "../reducers/NotificationReducer";
import Contracts from "../reducers/ContractsReducer";




export const store = configureStore({
  reducer: {
    event: EventDataReducer,
    Statistics,
    Notification,
    Contracts,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      // Add other middleware configurations here if needed
    }),
});