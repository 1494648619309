import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getNotificationCount,
  getNotifications,
  handleAction,
  handleRead,
} from "../../services/NotificationServices";

const initialState = {
  count: 0,
  notifications: null,
  isLoading: true,
};

export const getNotificationUnreadCount = createAsyncThunk(
  "unread",
  getNotificationCount
);

export const getNotificationsContent = createAsyncThunk(
  "notifications/content",
  getNotifications
);

export const handleNotificationRead = createAsyncThunk(
  "notifications/read",
  handleRead
);

export const handleNotificationAction = createAsyncThunk(
  "notifications/action",
  handleAction
);

const EventDataReducer = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: {
    [getNotificationUnreadCount.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getNotificationUnreadCount.fulfilled]: (state, action) => {
      const { data } = action.payload;

      state.count = data;
      state.isLoading = false;
      return state;
    },
    [getNotificationsContent.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.notifications = data;
      state.isLoading = false;
      return state;
    },
    [getNotificationUnreadCount.rejected]: (state, action) => {
      state.isLoading = false;
      return state;
    },
  },
});

export default EventDataReducer.reducer;
