import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import React, { useEffect, Fragment, useContext, lazy } from "react";
import { AppContext } from "../../provider/AppContext";
import Login from "../../redesign/auth/LoginForm";
import ForgotPassword from "../../redesign/auth/ForgotPassword";
import ResetPassword from "../../redesign/auth/ResetPassword";
import UpdateUserDetails from "../../redesign/Setting/UpdateProfile";
import { logoutUser, verifyProfile } from "../../services/AuthServices";
import { employeeDetail } from "../../services/EmployeeService";
import { executiveRoles } from "../NavBar/Navbar";
const CreateUserProfile = lazy(() => import("../User/CreateUserProfile"));
const EmbedTrello = lazy(() => import("../EmbedTrello/Index"));
const Support = lazy(() => import("../Support/Index"));
const PrivacyPolicy = lazy(() => import("../../pages/PrivacyPolicy"));
const TermsAndConditions = lazy(() => import("../../pages/Terms"));
const CKTemplateEditor = lazy(() => import("../Template/Index"));
const ContractTemplate = lazy(() => import("../Setting/ContractTemplates"));
const AllPricing = lazy(() => import("../Pricing/AllPricing.js"));
const ContractOverview = lazy(() => import("../Contracts/ContractOverview"));
const ErrorPage = lazy(() => import("../../pages/ErrorPage"));
const Navbar = lazy(() => import("../NavBar/Navbar"));
const SignUpForm = lazy(() => import("../../auth/SignupForm"));
const EditUser = lazy(() => import("../User/EditUser"));
const Home = lazy(() => import("../../pages/Home"));
const UpdatePassword = lazy(() => import("../Setting/UpdatePassword"));
const EmployeesRoute = lazy(() => import("../Setting/Employees"));
const RestoreContract = lazy(() => import("../Setting/RestoreContractTemplate"));
const AdobeSignWrapper = lazy(() =>
  import("../Contracts/AdobeSign/AdobeSignWrapper")
);
const UpdateUserProfile = lazy(() => import("../Setting/UpdateUserProfiles"));

function WebRoutes() {
  const {
    isAuthenticated,
    isLoading,
    setLoading,
    socket,
    initialFetched,
    setIsAuthenticated,
    handleReset,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const checkAuthenticated = async () => {
    if (!localStorage.token) return setLoading(false);
    try {
      const res = await verifyProfile();
      res ? setIsAuthenticated(true) : setIsAuthenticated(false);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err.message);
    }
  };

  useEffect(() => {
    checkAuthenticated();
    // eslint-disable-next-line
  }, []);

  const setAuth = (boolean) => {
    setIsAuthenticated(boolean);
  };

  const AuthLoginNew = () => {
    return !isAuthenticated ? (
      <Login setAuth={setAuth} />
    ) : (
      <Navigate to="/contracts" />
    );
  };

  const logout = async (e) => {
    await logoutUser();
    e.preventDefault();
    try {
      setAuth(false);
      handleReset();
      socket.emit("logout");
      initialFetched.current = false;
      localStorage.removeItem("token");
    } catch (err) {
      console.error(err.message);
    }
  };

  const routes = [
    {
      path: "/",
      Component: Home,
      allowedPrivate: true,
    },
    {
      path: "/home",
      Component: Home,
      allowedPrivate: true,
    },
    {
      path: "/pricing/:id",
      Component: AllPricing,
      allowedPrivate: true,
    },
    {
      path: "/createemployee",
      Component: CreateUserProfile,
      allowedPrivate: true,
    },
    {
      path: "/account",
      Component: EditUser,
      allowedPrivate: true,
    },
    {
      path: "/contracts/:tabid",
      Component: ContractOverview,
      allowedPrivate: true,
    },
    {
      path: "/contracts",
      Component: ContractOverview,
      allowedPrivate: true,
    },
    {
      path: "/stats/:tabid",
      Component: ContractOverview,
      allowedPrivate: true,
    },
    {
      path: "/stats",
      Component: ContractOverview,
      allowedPrivate: true,
    },
    {
      path: "/template-contract",
      Component: CKTemplateEditor,
      allowedPrivate: true,
    },
    {
      path: "/template-contract/:id",
      Component: CKTemplateEditor,
      allowedPrivate: true,
    },
    {
      path: "/template-contract/:mode/:id",
      Component: CKTemplateEditor,
      allowedPrivate: true,
    },
    {
      path: "/support",
      Component: Support,
      allowedPrivate: true,
    },
    {
      path: "/tpe_support",
      Component: EmbedTrello,
      allowedPrivate: true,
    },

    {
      path: "/login",
      Component: AuthLoginNew,
    },
    {
      path: "/privacy-policy",
      Component: PrivacyPolicy,
    },
    {
      path: "/terms-of-service",
      Component: TermsAndConditions,
    },
    {
      path: "/login_new",
      Component: AuthLoginNew,
    },
    {
      path: "/reset",
      Component: ForgotPassword,
    },

    {
      path: "/reset_new",
      Component: ForgotPassword,
    },
    {
      path: "/confirm_reset/:token",
      Component: ResetPassword,
    },
    {
      path: "/confirm_reset_new/:token",
    },
    {
      path: "/signup",
      Component: SignUpForm,
    },
    {
      path: "/password",
      Component: UpdatePassword,
      allowedPrivate: true,
    },
    {
      path: "/profile",
      Component: UpdateUserDetails,
      allowedPrivate: true,
    },
    {
      path: "/profile_new",
      Component: UpdateUserDetails,
      allowedPrivate: true,
    },
    {
      path: "/employees",
      Component: EmployeesRoute,
      allowedPrivate: true,
      isAdminRoute: true,
    },
    {
      path: "/restore-contract",
      Component: RestoreContract,
      allowedPrivate: true,
    },
    {
      path: "/approved-contract-templates",
      Component: ContractTemplate,
      allowedPrivate: true,
    },
    {
      path: "/adobesign",
      Component: AdobeSignWrapper,
    },
    {
      path: "/employees/:id",
      Component: UpdateUserProfile,
      allowedPrivate: true,
      isAdminRoute: true,
    },
    {
      path: "/*",
      Component: ErrorPage,
    },
  ];

  const { user, setUser } = useContext(AppContext);

  useEffect(() => {
    if (!user && localStorage.token)
      employeeDetail()
        .then((data) => {
          if (!data?.employee) return setIsAuthenticated(false);
          socket.emit("login_user", {
            ...(data?.employee || {}),
            socketId: socket.id,
          });

          setUser(data.employee);
        })
        .catch(({ data }) => {
          if (data.message) {
            localStorage.removeItem("token");
            navigate("/login");
          }
        });
    // eslint-disable-next-line
  }, [user, setUser]);

  const { role } = user || {};
  const isAdmin = executiveRoles.includes(role);

  const isUser =
    role === "event director" ||
    role === "Event Director" ||
    role === "sale" ||
    role === "Sale" ||
    role === "Event Director Assistant" ||
    role === "Assistant" ||
    role === "event director assistant";

  return (
    <Fragment>
      <Navbar
        isAdmin={isAdmin}
        isUser={isUser}
        isAuthenticated={isAuthenticated}
        logout={logout}
      />
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <React.Suspense fallback={<span>Loading...</span>}>
          <Routes>
            {routes.map(
              ({ path, Component, allowedPrivate, isAdminRoute }, index) => {
                const isPrivate = !isAuthenticated && allowedPrivate;
                const isAllowedRoute = ["Admin", "Owner"].includes(user?.role);

                return (
                  <Route
                    path={path}
                    key={index}
                    element={
                      isPrivate ? (
                        <Navigate to="/login" />
                      ) : isAdminRoute && !isAllowedRoute ? (
                        <Navigate to="/home" />
                      ) : (
                        <Component setAuth={setAuth} />
                      )
                    }
                  />
                );
              }
            )}
          </Routes>
        </React.Suspense>
      )}
    </Fragment>
  );
}

export default WebRoutes;
