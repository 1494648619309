import React, { useContext, useEffect } from "react";
// import Modal from "react-bootstrap/Modal";
import { Modal } from "rsuite";
import EventDate from "../../atoms/MUI/Date";
import { useState } from "react";
import { AppContext } from "../../provider/AppContext";
import AAutoComplete from "../../atoms/MUI/AutoComplete";
import { Grid } from "@mui/material";
import ANumberInput from "../../atoms/MUI/NumberInputs";
import { initialState } from "./ContractForm";
import LoadingIcons from "react-loading-icons";
import { extractIds, extractPhantomData } from "../../helper";
import { InputAdornment } from "@mui/material";
import {
  createContract,
  updateContract,
} from "../../services/ContractServices";
import PublicIcon from "@mui/icons-material/Public";
import PersonIcon from "@mui/icons-material/Person";
import SchoolIcon from "@mui/icons-material/School";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useTheme } from "../../ThemeContext";
import { useDispatch } from "react-redux";
import { toggleTrigger } from "../../redux/reducers/EventReducer";
import { executiveRoles } from "../NavBar/Navbar";
const PhantomForm = ({ setShowForm, showForm, contractState }) => {
  const handleClose = () => setShowForm(false);
  const [isLoading, setIsLoading] = useState(false);
  const themeToggle = useTheme();
  const mode = themeToggle.themeState?.mode;
  const dispatch = useDispatch();
  const {
    getRegion,
    getClientType,
    user,
    salesEmpWithOptions,
    getSchoolName,
    setContracts,
    getClientName,
    updateClientName,
    updateClientType,
    updateRegion,
    updateSchool,
  } = useContext(AppContext);
  const [summary, setSummary] = useState({
    signDate: "",
    startDate: "",
    contractStatus: "In Progress",
  });

  const onChange = (e) => {
    const obj = {
      ...summary,
      ...e,
    };
    setSummary(obj);
  };

  const { role, firstName } = user || {};
  const allowedCreate = ["Olivia", "Lauren"].includes(firstName);

  const isAssistant = role?.toLowerCase() === "event director assistant";
  const isAdmin = executiveRoles.includes(role);

  const isAdminOwner = ["Admin", "Owner"].includes(role);

  useEffect(() => {
    if (contractState) {
      setIsLoading(true);
      setSummary(extractPhantomData(contractState));
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } else {
      setSummary({ startDate: "", contractStatus: "In Progress" });
    }
  }, [contractState]);

  // Invokes updateClientType with the provided name and client type from the App context
  const handleClientTypeUpdate = (name) => {
    return updateClientType(name);
  };

  // Invokes updateClientName with the provided name and client type from the App context
  const handleClientUpdate = (name) => {
    return updateClientName(name, summary?.clientType?.id);
  };

  // Invokes updateRegion with the provided name and current region name from the App context
  const handleRegionUpdate = (name) => {
    return updateRegion(name);
  };

  const COL_LABELS = [
    { label: "In Progress", contract_status: "In Progress" },
    { label: "Sent", contract_status: "Sent" },
    { label: "Signed/Paid", contract_status: "Signed/Paid", isAdmin: true },
    { label: "Signed/Unpaid", contract_status: "Signed/Unpaid" },
    {
      label: "Signed/Partially Paid",
      contract_status: "Signed/Partially Paid",
      isAdmin: true,
    },
    { label: "Reconciled", contract_status: "Reconciled", isAdmin: true },
    { label: "Canceled", contract_status: "Canceled" },
    { label: "Postponed", contract_status: "Postponed" },
  ].filter(({ isAdmin }) => (isAdminOwner ? true : !isAdmin));

  const onSubmitForm = async () => {
    const body = {
      ...summary,
    };

    (summary?.id
      ? updateContract(
          summary?.id,
          extractIds(body, {
            addClientId: true,
          })
        )
      : createContract(extractIds({ ...body, isPhantom: true }))
    ).then((res) => {
      setContracts((prev) => {
        const index = prev.findIndex((contract) => contract.id === res?.id);
        if (index !== -1) {
          // Contract with matching ID found, update it
          return prev.map((contract, i) =>
            i === index ? { ...res } : contract
          );
        } else if (res?.id) {
          // Contract with specified ID not found, add it to the end
          return [...prev, { ...res }];
        } else {
          // No ID specified, do not modify the contracts array
          return prev;
        }
      });

      setSummary(initialState);
      setShowForm(false);
      dispatch(toggleTrigger());
    });
  };

  const handleCollaborator = (e, index) => {
    const obj = [...(summary?.eventClients || [])];
    obj[index] = {
      ...(summary?.eventClients?.[index] || {}),
      collaborator: e?.collaborator,
    };
    onChange({
      eventClients: obj,
    });
  };

  const handelClient = (e, index) => {
    const obj = [...(summary?.eventClients || [])];
    obj[index] = {
      ...(summary?.eventClients?.[index] || {}),
      ...e?.eventClients,
      client: { id: e?.eventClients.id, name: e?.eventClients.name },
      id: summary?.eventClients?.[index]?.id,
    };

    onChange({
      eventClients: obj,
    });
  };
  const closeIconStyles = {
    color: "red", // Set the color to red
    fontSize: "20px", // Set the font size
    // Add any other custom styles you want
  };

  return (
    <Modal size="lg" open={showForm} onClose={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title
          style={{
            fontWeight: 900,
            fontSize: "25px",
            textTransform: "none",
          }}
        >
          {contractState ? "Edit" : "Create"} Phantom Contract
          <span className="modal-header-close-icon" style={closeIconStyles} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#000",
              flexDirection: "column",
              gap: "5px",
              width: "100%",
              height: "100%",
              minHeight: "20vh",
            }}
          >
            <LoadingIcons.SpinningCircles
              stroke="#000"
              fill="#000"
              height={"3em"}
              speed={0.75}
            />
            Loading contract data...
          </div>
        )}
        {!isLoading && (
          <Grid container spacing={0}>
            <Grid item xs={12} md={4}>
              <EventDate
                format={"YYYY/MM/DD"}
                value={summary.startDate || ""}
                name="startDate"
                label={"Event Date"}
                set={onChange}
              />
              {/* <EventDate value={summary.startDate} name="+" set={onChange} /> */}
            </Grid>
            <Grid item xs={12} md={4}>
              <AAutoComplete
                set={onChange}
                update={handleRegionUpdate}
                option={getRegion}
                value={summary.region?.name}
                name="region"
                labelValue="name"
                id="Region"
                label="Region"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PublicIcon
                        style={{
                          marginLeft: "5px",
                          color: mode === "dark" ? "#E6E6E6" : "#A3A3A3",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <AAutoComplete
                set={onChange}
                isNotCreatable={!isAdmin && !allowedCreate}
                update={handleClientTypeUpdate}
                option={getClientType}
                checkDisabled
                value={summary?.clientType?.name}
                name="clientType"
                isCheckApproved
                labelValue="name"
                id="Client Type"
                label="Client Type"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon
                        style={{
                          marginLeft: "5px",
                          color: mode === "dark" ? "#E6E6E6" : "#A3A3A3",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            {isAssistant && (
              <Grid item xs={12} md={4}>
                <AAutoComplete
                  set={onChange}
                  isNotCreatable={true}
                  option={salesEmpWithOptions}
                  value={summary.soldBy?.firstName}
                  name="soldBy"
                  labelValue="name"
                  id="Sold By"
                  label="Sold By"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ShoppingCartIcon
                          style={{
                            marginLeft: "5px",
                            color: mode === "dark" ? "#E6E6E6" : "#A3A3A3",
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}

            <Grid item xs={12} md={4}>
              <AAutoComplete
                set={(name) => handelClient(name, 0)}
                update={updateClientName}
                option={getClientName}
                value={summary?.eventClients?.[0]?.client?.name}
                name="eventClients"
                labelValue="name"
                id="Client"
                label={"Client Name #1"}
                error={
                  summary?.clientType?.name?.length > 1
                    ? ""
                    : "Select Client Type First"
                }
              />
            </Grid>
            {["COLLEGE", "PREP"].includes(summary?.clientType?.name) && (
              <Grid item xs={12} md={4}>
                <AAutoComplete
                  set={(name) => handleCollaborator(name, 0)}
                  update={updateSchool}
                  option={getSchoolName}
                  value={summary?.eventClients?.[0]?.collaborator?.name}
                  name="collaborator"
                  labelValue="name"
                  id="School"
                  label="School"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SchoolIcon
                          style={{
                            marginLeft: "5px",
                            color: mode === "dark" ? "#E6E6E6" : "#A3A3A3",
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}

            <Grid item xs={12} md={4}>
              <AAutoComplete
                set={(name) => handelClient(name, 1)}
                update={handleClientUpdate}
                option={getClientName}
                value={summary?.eventClients?.[1]?.client?.name}
                name="eventClients"
                labelValue="name"
                id="Client"
                label={"Client Name #2"}
              />
            </Grid>
            {["COLLEGE", "PREP"].includes(summary.clientType?.name) && (
              <Grid item xs={12} md={4}>
                <AAutoComplete
                  set={(name) => handleCollaborator(name, 1)}
                  update={updateSchool}
                  option={getSchoolName}
                  value={summary?.eventClients?.[1]?.collaborator?.name}
                  name="collaborator"
                  labelValue="name"
                  id="School"
                  label="School"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SchoolIcon
                          style={{
                            marginLeft: "5px",
                            color: mode === "dark" ? "#E6E6E6" : "#A3A3A3",
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}

            <Grid item xs={12} md={4}>
              <ANumberInput
                isAmount
                set={onChange}
                sx_form={{ width: "100%", p: 1 }}
                value={summary.clientBudget}
                name="clientBudget"
                label="Revenue"
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <ANumberInput
                isAmount
                set={onChange}
                sx_form={{ width: "100%", p: 1 }}
                value={summary.totalCost}
                name="totalCost"
                label="Cost ($)"
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <EventDate
                format={"YYYY/MM/DD"}
                value={summary.signDate || ""}
                name="signDate"
                label={"Sign Date"}
                set={onChange}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <AAutoComplete
                set={onChange}
                option={COL_LABELS}
                value={summary?.contractStatus}
                name="contractStatus"
                labelValue="contract_status"
                keyValue={"contractStatus"}
                id="Contract Status"
                label="Contract Status"
              />
            </Grid>
          </Grid>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={handleClose}
          style={{
            width: "99px",
            height: "40px",
            background: mode === "dark" ? "#212121" : "#F6F6F6",
            borderRadius: "8px",
            padding: "10px",
            transition: "background-color 0.3s",
            marginRight: "10px",
            color: mode === "dark" ? "white" : "black",
          }}
          onMouseEnter={(e) => {
            e.target.style.background =
              mode === "dark" ? "darkgray" : "#E6E6E6";
          }}
          onMouseLeave={(e) => {
            e.target.style.background = mode === "dark" ? "#212121" : "#F6F6F6";
          }}
        >
          Close
        </button>
        <button
          onClick={onSubmitForm}
          style={{
            width: "185px",
            height: "40px",
            background: "#48B0DB",
            borderRadius: "8px",
            padding: "10px",
            transition: "background-color 0.3s",
            color: "white",
            marginRight: "7px",
          }}
          onMouseEnter={(e) => {
            e.target.style.background = "#398baf";
          }}
          onMouseLeave={(e) => {
            e.target.style.background = "#48B0DB";
          }}
        >
          {contractState ? "Update" : "Create"} Contract
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default PhantomForm;
