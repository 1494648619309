import axios from "axios";
import { MakeCancelable } from "./CancelablePromise";
import qs from "qs";


const config = {
  // "XSRF-TOKEN": xsrfToken,
  // version: "httpOnly",
};

export var instance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  withCredentials: false,
  transformRequest: [
    (data) => {
      return data;
    },
    ...axios.defaults.transformRequest,
  ],
});

registerInterceptor(instance);

export const addSocketIdInterceptor = (socket) => {
  instance.interceptors.request.use((config) => {
    // Check if the request contains multipart/form-data
    if (
      config.headers["Content-Type"] &&
      config.headers["Content-Type"].startsWith("multipart/form-data")
    ) {
      return config; // Skip adding socketId to the request
    }

    config.data = {
      ...config.data,
      socketId: socket.id,
    };
    return config;
  });
};

function registerInterceptor(instance) {
  instance.interceptors.response.use(
    function (response) {
      // Success
      return Promise.resolve(response.data);
    },
    function (error) {
      // Handle response error
      if (error.response) {
        // The request was made and the server responded with a status code
        const status = error.response.status;
        const message = error.response.data.message;
        if (status === 401 && message === "Invalid or expired token!") {
          localStorage.removeItem("token");
          // Redirect to the login page if not already redirected
          window.location.href = "/login";
        }

        // Return the error details as a rejected promise
        return Promise.reject({
          data: error.response.data,
          status: status,
          statusText: error.response.statusText,
        });
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        return Promise.reject({
          data: "",
          status: 0,
          statusText: "No Response",
        });
      } else {
        // Something happened in setting up the request that triggered an Error
        return Promise.reject({
          data: "",
          status: 0,
          statusText: error.message,
        });
      }
    }
  );
}

// Add an interceptor to set the authorization header before each request
instance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("token");

    config.headers["ngrok-skip-browser-warning"] = true;

    if (accessToken) {
      config.headers["Authorization"] = accessToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

async function get(url, data) {
  const cancelablePromise = MakeCancelable(
    instance.get(url, { ...data, ...config })
  );

  const response = await cancelablePromise.promise;
  return response;
}

async function post(url, data) {
  const cancelablePromise = MakeCancelable(
    instance.post(url, {
      ...data,
      ...config,
    })
  );

  const response = await cancelablePromise.promise;
  return response;
}

async function put(url, data) {
  const cancelablePromise = MakeCancelable(
    instance.put(url, data, {
      params: config,
      paramsSerializer: (params) =>
        qs.stringify(params, {
          allowDots: true,
          arrayFormat: "indices",
          encode: false,
        }),
    })
  );

  const response = await cancelablePromise.promise;
  return response;
}

async function patch(url, data, params) {
  const cancelablePromise = MakeCancelable(
    instance.patch(url, data, {
      params: { ...params, ...config },
      paramsSerializer: (params) =>
        qs.stringify(params, {
          allowDots: true,
          arrayFormat: "indices",
          encode: false,
        }),
    })
  );

  const response = await cancelablePromise.promise;
  return response;
}

async function destroy(url, data = {}) {
  const cancelablePromise = MakeCancelable(
    instance.delete(url, {
      params: { ...data, ...config },
    })
  );

  const response = await cancelablePromise.promise;
  return response;
}

async function upload(url, data) {
  Object.keys(config).forEach((key) => {
    data.append(key, config[key]);
  });
  const cancelablePromise = MakeCancelable(instance.post(url, data));

  const response = await cancelablePromise.promise;
  return response;
}

const download = async (url, data) => {
  const cancelablePromise = MakeCancelable(
    instance.get(url, {
      responseType: "blob",
      contentType: "application/octet-stream",
      ...data,
    })
  );

  const response = await cancelablePromise.promise;
  return response;
};

const submitFormData = async (url, formData) => {
  const cancelablePromise = MakeCancelable(
    instance.post(url, formData, {
      headers: {
        ...config,
        "Content-Type": "multipart/form-data",
      },
    })
  );

  const response = await cancelablePromise.promise;
  return response;
};

function isLoggedIn() {
  return false;
}
export {
  post,
  get,
  put,
  patch,
  destroy,
  download,
  upload,
  isLoggedIn,
  submitFormData,
};
