import React, { useContext } from "react";
import { Modal } from "rsuite";
import EventDate from "../../atoms/MUI/Date";
import { useState } from "react";
import { AppContext } from "../../provider/AppContext";
import AAutoComplete from "../../atoms/MUI/AutoComplete";
import { Grid } from "@mui/material";
import { extractIds, generateToast } from "../../helper";
import { ERROR } from "../../constant";
import { createContract } from "../../services/ContractServices";
import PublicIcon from "@mui/icons-material/Public";
import PersonIcon from "@mui/icons-material/Person";
import SchoolIcon from "@mui/icons-material/School";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useTheme } from "../../ThemeContext";
import { InputAdornment } from "@mui/material";
import { Loader } from "rsuite";
import { useDispatch, useSelector } from "react-redux";
import { toggleTrigger } from "../../redux/reducers/EventReducer";
import { executiveRoles } from "../NavBar/Navbar";
import TextField from "@mui/material/TextField";
import { handleParentFilter } from "../../redux/reducers/ContractsReducer";

export const initialState = {};
export const rentalInitial = [
  {
    expenseHead: "Rental",
  },
  {
    expenseHead: "Staff",
  },
  {
    expenseHead: "Cleaning",
  },
];

export const foodInitial = [
  {
    expenseHead: "Appetizers",
    shouldShowOnContract: true,
  },
  {
    expenseHead: "Dinner",
    shouldShowOnContract: true,
  },
];

export const bevInitial = [
  {
    expenseHead: "Bar Tab",
  },
  {
    expenseHead: "Client Drinks",
  },
];

const ContactForm = ({ setShowForm, showForm, handleClick }) => {
  const handleClose = () => setShowForm(false);
  const dispatch = useDispatch();
  const themeToggle = useTheme();
  const mode = themeToggle.themeState?.mode;
  const [isLoading, setIsLoading] = useState(false);
  const {
    getRegion,
    getClientType,
    user,
    salesEmpWithOptions,
    fetchParentOptions,
    getSchoolName,
    fetchDetails,
    getClientName,
    updateClientName,
    updateClientType,
    updateRegion,
    updateSchool,
  } = useContext(AppContext);

  const {
    Contracts: { parentFilter },
  } = useSelector((state) => state);

  const handleDispatch = () => {
    dispatch(handleParentFilter({ ...parentFilter }));
  };

  const isParentForm = showForm === 2;
  const [summary, setSummary] = useState({
    startDate: "",
  });

  const onChange = (e) => {
    const obj = {
      ...summary,
      ...e,
    };
    setSummary(obj);
  };

  const handelClient = (e) => {
    onChange({
      eventClients: [
        {
          ...(summary?.eventClients?.[0] || {}),
          ...e?.eventClients,
          client: { id: e?.eventClients.id, name: e?.eventClients.name },
        },
      ],
    });
  };

  const handleCollaborator = (e) => {
    onChange({
      eventClients: [
        { ...summary?.eventClients?.[0], collaborator: e.collaborator },
      ],
    });
  };

  const { role, firstName } = user || {};
  const allowedCreate = ["Olivia", "Lauren"].includes(firstName);
  const isAssistant = role?.toLowerCase() === "event director assistant";
  const isAdmin = executiveRoles.includes(role);

  const onSubmitForm = async () => {
    setIsLoading(true);

    if (!isParentForm && !summary?.clientType) {
      setIsLoading(false);
      return generateToast("Client Type is required!", ERROR);
    }

    if (!isParentForm && !summary?.startDate) {
      setIsLoading(false);
      return generateToast("Event Date is required!", ERROR);
    }

    if (!isParentForm && !summary?.region) {
      setIsLoading(false);
      return generateToast("Region is required!", ERROR);
    }

    if (!isParentForm && isAssistant && !summary?.soldBy) {
      setIsLoading(false);
      return generateToast("Sold by is required!", ERROR);
    }

    if (isParentForm && !summary?.title) {
      setIsLoading(false);
      return generateToast("Title by is required!", ERROR);
    }

    const filterSummary = Object.keys(summary)
      .filter(
        (key) =>
          summary?.[key] !== undefined &&
          summary?.[key] !== null &&
          summary?.[key] !== ""
      )
      .reduce((obj, key) => {
        obj[key] = summary[key];
        return obj;
      }, {});

    const contractsPromise = createContract(extractIds(filterSummary));

    contractsPromise
      .then((res) => {
        setIsLoading(false);
        dispatch(toggleTrigger());
        fetchDetails();
        handleDispatch();
        handleClick(res?.data);
        isParentForm && fetchParentOptions()
        setSummary({
          startDate: "",
        });
        setShowForm(false);
      })
      .catch(({ data }) => {
        setIsLoading(false);
      });
  };

  // Invokes updateClientType with the provided name and client type from the App context
  const handleClientTypeUpdate = (name) => {
    return updateClientType(name, summary?.clientType);
  };

  // Invokes updateClientName with the provided name and client type from the App context
  const handleClientUpdate = (name) => {
    return updateClientName(name, summary?.clientType?.id);
  };

  // Invokes updateRegion with the provided name and current region name from the App context
  const handleRegionUpdate = (name) => {
    return updateRegion(name);
  };

  // Invokes updateSchool with the provided name and current school name from the App context
  const handleSchoolUpdate = (name) => {
    return updateSchool(name);
  };

  return (
    <Modal size="xs" open={showForm} onClose={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title
          style={{
            fontWeight: 900,
            fontSize: "20px",
            textTransform: "none",
          }}
        >
          {!isParentForm ? "Create Contract Draft" : "Create Parent Contract"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isParentForm ? (
          <TextField
            size="small"
            sx={{
              mb: 0,
              m: 1,
              display: "flex",
            }}
            onChange={(e) => onChange({ [e.target.name]: e.target.value })}
            type="text"
            name="title"
            label="Contract Title"
            value={summary.title}
          />
        ) : (
          <>
            <EventDate
              format={"YYYY/MM/DD"}
              value={summary.startDate}
              name="startDate"
              set={onChange}
            />
            <AAutoComplete
              set={onChange}
              update={handleRegionUpdate}
              option={getRegion}
              value={summary.region?.name}
              name="region"
              labelValue="name"
              id="Region"
              label="Region"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PublicIcon className="tw-ml-2" />
                  </InputAdornment>
                ),
              }}
            />
            <AAutoComplete
              set={onChange}
              sx={{ mt: 1 }}
              isNotCreatable={!isAdmin && !allowedCreate}
              update={handleClientTypeUpdate}
              option={getClientType}
              checkDisabled
              isCheckApproved
              value={summary?.clientType?.name}
              name="clientType"
              labelValue="name"
              id="Client Type"
              label="Client Type"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon className="tw-ml-2" />
                  </InputAdornment>
                ),
              }}
            />
            {isAssistant && (
              <AAutoComplete
                set={onChange}
                isNotCreatable={true}
                option={salesEmpWithOptions}
                value={summary.soldBy?.firstName}
                name="soldBy"
                labelValue="name"
                id="Sold By"
                label="Sold By"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ShoppingCartIcon className="tw-ml-2" />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            <Grid container spacing={0} sx={{ mt: -1 }}>
              <Grid item xs={12} md={12}>
                <AAutoComplete
                  sx={{ mt: 1 }}
                  set={handelClient}
                  update={handleClientUpdate}
                  option={getClientName}
                  value={summary?.eventClients?.[0]?.name}
                  name="eventClients"
                  labelValue="name"
                  id="Client"
                  label={"Client Name #1"}
                  error={
                    summary?.clientType?.length > 1
                      ? ""
                      : "Select Client Type First"
                  }
                />
              </Grid>
              <Grid item xs={12} md={12}>
                {["COLLEGE", "PREP"].includes(summary?.clientType?.name) && (
                  <>
                    <AAutoComplete
                      sx={{ mt: 1 }}
                      set={handleCollaborator}
                      update={handleSchoolUpdate}
                      option={getSchoolName}
                      value={summary?.eventClients?.[0]?.collaborator?.name}
                      name="collaborator"
                      labelValue="name"
                      id="School"
                      label="School"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SchoolIcon className="tw-ml-2" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={1} md={1}></Grid>
            </Grid>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={handleClose}
          style={{
            width: "99px",
            height: "40px",
            background: mode === "dark" ? "#212121" : "#F6F6F6",
            borderRadius: "8px",
            padding: "10px",
            transition: "background-color 0.3s",
            marginRight: "10px",
            color: mode === "dark" ? "white" : "black",
          }}
          onMouseEnter={(e) => {
            e.target.style.background =
              mode === "dark" ? "darkgray" : "#E6E6E6";
          }}
          onMouseLeave={(e) => {
            e.target.style.background = mode === "dark" ? "#212121" : "#F6F6F6";
          }}
        >
          Close
        </button>
        <button
          onClick={onSubmitForm}
          style={{
            width: "153px",
            height: "40px",
            background: "#48B0DB",
            borderRadius: "8px",
            padding: "10px",
            transition: "background-color 0.3s",
            color: "white",
            marginRight: "7px",
          }}
          onMouseEnter={(e) => {
            e.target.style.background = "#398baf";
          }}
          onMouseLeave={(e) => {
            e.target.style.background = "#48B0DB";
          }}
          disabled={isLoading}
        >
          {isLoading ? (
            <Loader size="sm" content="Loading..." />
          ) : (
            "Create Draft"
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ContactForm;
