import React, { useMemo, useEffect, useState, useCallback } from "react";
import { calculateActiveStatus } from "../../helper";
import { throttle } from "lodash";

const ActiveStatus = ({ loginTime, logoutTime }) => {
  const [activeStatus, setActiveStatus] = useState(() =>
    calculateActiveStatus(loginTime, logoutTime)
  );

  // Memoize the calculateActiveStatus to avoid recalculating unless inputs change
  const getActiveStatus = useCallback(
    () => calculateActiveStatus(loginTime, logoutTime),
    [loginTime, logoutTime]
  );

  useEffect(() => {
    // Throttle the updates to run every 60 seconds to reduce excessive calculations
    const throttledUpdate = throttle(() => {
      setActiveStatus(getActiveStatus());
    }, 60000); // Throttle the update every 60 seconds

    // Run initially to set the status
    throttledUpdate();

    // Set up the interval to update status periodically
    const intervalId = setInterval(throttledUpdate, 60000);

    // Clean up interval and throttled function on unmount
    return () => {
      clearInterval(intervalId);
      throttledUpdate.cancel(); // Cancel throttling on cleanup
    };
  }, [getActiveStatus]);

  return (
    <div>
      <p>{activeStatus}</p>
    </div>
  );
};

export default ActiveStatus;
