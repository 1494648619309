import { destroy, get, patch, post } from "./ServicesApi";

const createEventType = (body) => {
  const eventTypePromise = post("/v1/event-type", body);
  return eventTypePromise;
};

const updateEventTypeData = (id, body) => {
  const eventTypePromise = patch(`/v1/eventtype/${id}`, body);
  return eventTypePromise;
};

const getEventTypes = (id, body) => {
  const eventTypePromise = get(`/v1/event-type`);
  return eventTypePromise;
};

const addEventStaff = (body) => {
  const eventTypePromise = post(`/v1/staff-billing`, body);
  return eventTypePromise;
};

const deleteEventStaff = (id) => {
  const eventTypePromise = destroy(`/v1/staff-billing/${id}`);
  return eventTypePromise;
};

const updateEventStaff = (id, body) => {
  const eventTypePromise = patch(`/v1/staff-billing/${id}`, body);
  return eventTypePromise;
};

const resetEventStaff = (id) => {
  const eventTypePromise = patch(`/v1/event/${id}/reset-staff-billing`);
  return eventTypePromise;
};

const addProductionBilling = (body) => {
  const eventTypePromise = post(`/v1/production-billing`, body);
  return eventTypePromise;
};

const deleteProductionBilling = (id) => {
  const eventTypePromise = destroy(`/v1/production-billing/${id}`);
  return eventTypePromise;
};

const updateProductionBilling = (id, body) => {
  const eventTypePromise = patch(`/v1/production-billing/${id}`, body);
  return eventTypePromise;
};

const resetProductionBilling = (id, body) => {
  const eventTypePromise = patch(
    `/v1/event/${id}/reset-production-billing`,
    body
  );
  return eventTypePromise;
};

const addTransportation = (body) => {
  const eventTypePromise = post(`/v1/transportation-billing`, body);
  return eventTypePromise;
};

const deleteTransportation = (id) => {
  const eventTypePromise = destroy(`/v1/transportation-billing/${id}`);
  return eventTypePromise;
};

const updateTransportation = (id, body) => {
  const eventTypePromise = patch(`/v1/transportation-billing/${id}`, body);
  return eventTypePromise;
};

const addPickNDrop = (body) => {
  const eventTypePromise = post(`/v1/pick-drop`, body);
  return eventTypePromise;
};

const updatePickNDrop = (id, body) => {
  const eventTypePromise = patch(`/v1/pick-drop/${id}`, body);
  return eventTypePromise;
};

const deletePickNDrop = (id) => {
  const eventTypePromise = destroy(`/v1/pick-drop/${id}`);
  return eventTypePromise;
};

const addPayment = (body) => {
  const eventTypePromise = post(`/v1/payment`, body);
  return eventTypePromise;
};

const updatePayment = (id, body) => {
  const eventTypePromise = patch(`/v1/payment/${id}`, body);
  return eventTypePromise;
};

const deletePayment = (id) => {
  const eventTypePromise = destroy(`/v1/payment/${id}`);
  return eventTypePromise;
};

const suggestPayment = (id) => {
  const res = get(`/v1/event/${id}/payment-suggestions`);
  return res;
};

const addExpense = (body) => {
  const eventTypePromise = post(`/v1/expense`, body);
  return eventTypePromise;
};

const updateExpense = (id, body) => {
  const eventTypePromise = patch(`/v1/expense/${id}`, body);
  return eventTypePromise;
};

const deleteExpense = (id) => {
  const eventTypePromise = destroy(`/v1/expense/${id}`);
  return eventTypePromise;
};

const resetExpense = (id, body, params) => {
  const eventTypePromise = patch(`/v1/event/${id}/reset-expenses`, body, params);
  return eventTypePromise;
};

const getEventOptions = (params) => {
  const eventTypePromise = get(`/v1/event/options`, params);
  return eventTypePromise;
};

const assignParent = (id, body) => {
  return patch(`/v1/event/${id}/update-parent`, body);
};

export {
  createEventType,
  updateEventTypeData,
  getEventTypes,
  addEventStaff,
  resetEventStaff,
  deleteEventStaff,
  getEventOptions,
  updateEventStaff,
  addProductionBilling,
  deleteProductionBilling,
  updateProductionBilling,
  resetProductionBilling,
  addTransportation,
  deleteTransportation,
  updateTransportation,
  addPickNDrop,
  updatePickNDrop,
  deletePickNDrop,
  addPayment,
  updatePayment,
  deletePayment,
  suggestPayment,
  addExpense,
  updateExpense,
  deleteExpense,
  resetExpense,
  assignParent,
};
