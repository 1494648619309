import moment from "moment";
import {
  convertStringToNumber,
  generateToast,
  getKeyValue,
  getStatusColor,
} from ".";

var gapi = window.gapi;
/* 
    Update with your own Client Id and Api key 
  */
var CLIENT_ID =
  "97689750233-v4l3njm3dva99344t73orieqfqqc5aeh.apps.googleusercontent.com";
var API_KEY = "AIzaSyCt-Q6SBYRJzk_7BGPKfJ57mw0t53AcwTo";

var DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
];
var SCOPES = "https://www.googleapis.com/auth/calendar.events";
var CALENDAR_ID =
  "calendar@theperfectevent.com";

// Handle Function for event
export const handleCalendarClick = async (event, calendarId, onChange) => {
  gapi.load("client:auth2", () => {
    console.log("loaded client");

    gapi.client.init({
      apiKey: API_KEY,
      clientId: CLIENT_ID,
      discoveryDocs: DISCOVERY_DOCS,
      plugin_name: "Test App",
      scope: SCOPES,
    });

    gapi.client.load("calendar", "v3", () => console.log("bam!"));

    gapi.auth2
      .getAuthInstance()
      .signIn()
      .then(() => {
        let request;
        if (calendarId) {
          request = gapi.client.calendar.events.update({
            calendarId: CALENDAR_ID,
            eventId: calendarId,
            resource: event,
          });
        } else {
          request = gapi.client.calendar.events.insert({
            calendarId: CALENDAR_ID,
            resource: event,
          });
        }

        request.execute((event) => {
          if (["404", "403", "400"].includes(event?.code)) {
            return generateToast(event.message, "Error");
          }
          setTimeout(() => {
            window.open(event.htmlLink, "_blank");
          });
          generateToast(
            calendarId
              ? "Calendar Updated Successfully"
              : "Calendar Added Successfully",
            "Success"
          );
          if (event?.id) onChange({ calendar: event?.id });
        });
      })
      .catch((error) => {
        generateToast(error, "Error");

        console.log(error, "error");
      });
  });
};

// Google Calendar Execution Helper Functions
export const syncCalendar = async (summary, onChange, user, customEvent) => {
  const event = getCalendarEvent(summary, user?.employee);

  await handleCalendarClick(
    { ...event, ...(customEvent && { description: customEvent }) },
    summary?.calendar,
    onChange
  );
};

// Get Event From Calendar
export const getEventsFromCalendar = (data, onSuccess, onError) => {
  const updatedData = getCalendarEvent(data);
  gapi.load("client:auth2", () => {
    console.log("loaded client");

    gapi.client.init({
      apiKey: API_KEY,
      clientId: CLIENT_ID,
      discoveryDocs: DISCOVERY_DOCS,
      plugin_name: "Test App",
      scope: SCOPES,
    });

    gapi.client.load("calendar", "v3", () => {
      console.log("calendar loaded");

      gapi.auth2
        .getAuthInstance()
        .signIn()
        .then(() => {
          gapi.client.calendar.events
            .get({
              calendarId: CALENDAR_ID,
              eventId: data.calendar,
            })
            .then((response) => {
              const events = response.result;
              onSuccess(events, updatedData);
            })
            .catch((error) => {
              onError(error);
            });
        })
        .catch((error) => {
          onError(error);
        });
    });
  });
};

export function StaffInfo(eventStaffData) {
  let trans = "";

  eventStaffData?.forEach((eventStaff) => {
    const { quantity, staffType, serviceType, shouldShowOnContract } =
      eventStaff;
    if (
      quantity > 0 &&
      staffType.length > 0 &&
      shouldShowOnContract &&
      serviceType?.name !== "Staff Cost" &&
      shouldShowOnContract
    ) {
      trans += `<p>(${quantity}) ${staffType}</p>`;
    }
  });

  return trans;
}

export function PickupInfo(pickUpDetails, pickUpTime) {
  let pickup = "";
  const vehicleString = `<h3><strong>Vehicle Pick Up (Time: ${pickUpTime})</strong></h3>`;

  pickUpDetails?.forEach((pickUp) => {
    const { location } = pickUp;
    if (location) {
      pickup += `<li>@${location}</li><br/>`;
    }
  });

  return vehicleString + `<strong><ol>${pickup}</ol></strong>`;
}

export function dropOffInfo(dropOffDetails, dropOffTime) {
  let dropoff = "";
  const vehicleString = `<h3><strong>Vehicle Drop Off (Time: ${dropOffTime})</strong></h3>`;

  dropOffDetails?.forEach((dropOff) => {
    const { location } = dropOff;
    if (location) {
      dropoff += `<li>@${location}</li><br/>`;
    }
  });

  return vehicleString + `<strong><ol>${dropoff}</ol></strong>`;
}

export function TransportationInfo(transportationData) {
  let trans = "";

  transportationData?.forEach((transportation) => {
    const { quantity, capacity, transportCompany } = transportation;
    if (quantity > 0) {
      trans += `<p>(${quantity}) ${capacity} Passenger ${transportCompany}</p>`;
    }
  });

  return trans;
}

export function ProdInfo(productionData) {
  let trans = "";

  productionData?.forEach((production) => {
    const { quantity, equipmentType } = production;
    if (quantity > 0 && equipmentType?.length > 0) {
      trans += `<p>(${quantity}) ${equipmentType}</p>`;
    }
  });

  return trans;
}

// Venue Details For Food, Rental, Beverage,  ⬇️

export function FoodInfo(food) {
  let foodStr = "";

  food?.forEach((foodItem) => {
    const { description, expenseHead, shouldShowOnContract, cost } = foodItem;
    if (
      description &&
      expenseHead !== undefined &&
      shouldShowOnContract &&
      cost
    ) {
      foodStr += `<span> ${expenseHead} - ${description}</span>`;
    }
  });

  return foodStr;
}

export function BevInfo(beverage) {
  let bev = "";

  beverage?.forEach((beverageItem) => {
    const { description, expenseHead, shouldShowOnContract, cost } =
      beverageItem;
    if (
      description &&
      expenseHead !== undefined &&
      shouldShowOnContract &&
      cost
    ) {
      bev += `<span> ${expenseHead} - ${description}</span>`;
    }
  });

  return bev;
}

export function VenueInfo(rental) {
  let rentalStr = "";

  rental?.forEach((rentalItem) => {
    const { description, expenseHead, shouldShowOnContract, cost } = rentalItem;
    if (
      description &&
      expenseHead !== undefined &&
      shouldShowOnContract &&
      cost
    ) {
      rentalStr += `<span> ${expenseHead} - ${description}</span>`;
    }
  });

  return rentalStr;
}

// Venue Details For Food, Rental, Beverage,  ⬆️

export const getCalendarEvent = (summary, user) => {
  const {
    eventType,
    guestCount,
    startDate,
    whatsTheDeal,
    startTime: startTimeDate,
    endTime: endTimeDate,
    venue,
    isClientBooked,
    contractStatus,
    pickUpTime: pickUpTimeStr,
    dropOffTime: dropOffTimeStr,
    soldBy,
    pickNDrops,
    staffBillings = [],
    transportationBillings = [],
    productionBillings = [],
    expenses = [],
  } = summary || "";
  const processPickNDrops = () => {
    const pickUps = pickNDrops
      ?.filter((obj) => obj.type === "pickUp")
      .sort((a, b) => a.id - b.id);
    const dropOffs = pickNDrops
      ?.filter((obj) => obj.type === "dropOff")
      .sort((a, b) => a.id - b.id);

    return { pickUps, dropOffs };
  };

  const { pickUps, dropOffs } = processPickNDrops();

  const processExpenses = (expenseType) => {
    return expenses
      ?.filter((obj) => obj.expenseType?.name === expenseType)
      .sort((a, b) => a.id - b.id);
  };

  const beverage = processExpenses("Beverage");
  const food = processExpenses("Food");
  const rental = processExpenses("Misc");

  const eventDate = startDate && moment(startDate).format("MM/DD/YYYY");

  const getSchoolName = (index) => {
    return getKeyValue("School Name", index, summary);
  };

  const schoolName1 = getSchoolName(0);
  const schoolName2 = getSchoolName(1);
  const formattedSchoolName =
    schoolName1 && !schoolName2
      ? `${schoolName1} - `
      : schoolName1 && schoolName2
      ? `${schoolName1}/${schoolName2} - `
      : "";

  const title = `${formattedSchoolName}${
    String(getKeyValue("Client Name", 0, summary)) || ""
  }  ${eventType?.name ? String(eventType?.name) : ""}${
    eventDate ? ` (${String(eventDate)})` : ""
  }`;

  const hideTransHeader = transportationBillings?.map(
    (transportationData) => transportationData?.quantity
  );
  const addTransQuantHideHeader = hideTransHeader?.reduce(
    (a, b) => convertStringToNumber(a || 0) + convertStringToNumber(b || 0),
    0
  );
  const hideStaffHeader = staffBillings
    ?.filter(({ shouldShowOnContract }) => shouldShowOnContract)
    ?.map((eventStaffData) => eventStaffData?.quantity);
  const addStaffQuantHideHeader = hideStaffHeader?.reduce(
    (a, b) => convertStringToNumber(a || 0) + convertStringToNumber(b || 0),
    0
  );
  const hideProdHeader = productionBillings?.map(
    (productionData) => productionData?.quantity
  );
  const addProdQuantHideHeader = hideProdHeader?.reduce(
    (a, b) => convertStringToNumber(a || 0) + convertStringToNumber(b || 0),
    0
  );

  const pickUpTime =
    pickUpTimeStr && moment(pickUpTimeStr, "HH:mm:ss").format("h:mm a");
  const dropOffTime =
    dropOffTimeStr && moment(dropOffTimeStr, "HH:mm:ss").format("h:mm a");

  const venueAddress = dropOffs?.[0]?.location;
  const eventStr = eventDate
    ? `<strong>Event Date: ${eventDate} - ${moment(startDate).format(
        "dddd"
      )}</strong><br/><br/>`
    : "";
  const venueNameStr = !!venue?.name
    ? `<strong>Venue / Destination: ${venue?.name}</strong><br/><br/>`
    : "";
  const whoBookedVenueStr = !!isClientBooked
    ? `<strong>Who Booked The Venue?: CLIENT</strong><br/><br/>`
    : "";
  const guestCountStr = !!venue?.name
    ? `<strong>Guest Count: ${guestCount}</strong><br/><br/>`
    : "";
  const vehiclePickupStr =
    pickUpTime && addTransQuantHideHeader > 0
      ? `${PickupInfo(pickUps, pickUpTime)}`
      : "";
  const vehicleDropOffStr =
    dropOffTime && addTransQuantHideHeader > 0
      ? `${dropOffInfo(dropOffs, dropOffTime)}`
      : "";
  const transportStr =
    addTransQuantHideHeader > 0
      ? `<strong>TRANSPORTATION: (ALCOHOL IS STRICTLY PROHIBITED ON ALL VEHICLES):</strong>${TransportationInfo(
          transportationBillings
        )}`
      : "";
  const staffStr =
    addStaffQuantHideHeader > 0
      ? `<br><strong>STAFF:</strong><br />${StaffInfo(staffBillings)}<br/>`
      : "";
  const prodStr =
    addProdQuantHideHeader > 0
      ? `<strong>PRODUCTION:</strong><br />${ProdInfo(productionBillings)}`
      : "";
  const directStr = `<br/><strong><i>EVENT DIRECTOR: ${soldBy?.firstName} ${soldBy?.lastName}</i></strong><br /><br />`;
  const onSiteRep = `<i>ON-SITE REP:</i><br /><br />`;
  const onBusLoadingRep = `<i>BUS LOADING REP:</i><br /><br />`;

  const isRental = rental?.some(
    (rental) =>
      rental.description &&
      rental.expenseHead &&
      rental.cost &&
      rental.shouldShowOnContract
  );
  const isFood = food?.some(
    (food) =>
      food.description &&
      food.expenseHead &&
      food.cost &&
      food.shouldShowOnContract
  );
  const isBeverages = beverage?.some(
    (beverage) => beverage.expenseHead && beverage.shouldShowOnContract
  );

  const venueDeal = whatsTheDeal
    ? `<strong>VENUE DEAL:</strong><br/><br/><strong>What's The Deal?:</strong> ${whatsTheDeal}<br/><br/>`
    : "";
  const foodStr = isFood
    ? `<strong>FOOD:</strong> ${FoodInfo(food)}<br/><br/>`
    : "";
  const bevStr = isBeverages
    ? `<strong>BAR:</strong> ${BevInfo(beverage)}<br/><br/>`
    : "";
  const venueStr = isRental
    ? `<strong>VENUE:</strong> ${VenueInfo(rental)}<br/><br/>`
    : "";

  const eventDateStr = moment(startDate).format("MM/DD/YYYY");
  const startTimeStr =
    startTimeDate && moment(startTimeDate, "HH:mm:ss").format("HH:mm");
  const endTimeStr =
    endTimeDate && moment(endTimeDate, "HH:mm:ss").format("HH:mm");

  const startDatetime = moment(eventDateStr)
    .hours(startTimeStr.split(":")[0])
    .minutes(startTimeStr.split(":")[1]);
  let endDate = moment(eventDateStr); // Initialize end date as event date
  let endDatetime = moment(endDate)
    .hours(endTimeStr.split(":")[0])
    .minutes(endTimeStr.split(":")[1]);

  if (startDatetime.isAfter(endDatetime)) {
    endDate = endDate.add(1, "day");
    endDatetime = moment(endDate)
      .hours(endTimeStr.split(":")[0])
      .minutes(endTimeStr.split(":")[1]);
  }

  // Referenced Strings for Google Cal Description ⬆️⬆️⬆️

  // Google Cal Parameters ⬇️⬇️⬇️
  return {
    summary: title,
    location: venueAddress || "",
    description: `${eventStr}${venueNameStr}${whoBookedVenueStr}${guestCountStr}${venueDeal}${foodStr}${bevStr}${venueStr}${vehiclePickupStr}${vehicleDropOffStr}${transportStr}${staffStr}${prodStr}${directStr}${onSiteRep}${onBusLoadingRep}`,
    start: {
      dateTime: startDatetime.utc().format("YYYY-MM-DDTHH:mm:ss.000") || "",
      timeZone: "UTC",
    },
    end: {
      dateTime: endDatetime.utc().format("YYYY-MM-DDTHH:mm:ss.000") || "",
      timeZone: "UTC",
    },
    colorId: getStatusColor(contractStatus),
    attendees: [],
    recurrence: [],
    reminders: {
      useDefault: false,
      overrides: [
        { method: "email", minutes: 24 * 60 },
        { method: "popup", minutes: 10 },
      ],
    },
  };
};
