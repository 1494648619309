import theme from "styled-theming";
import { makeStyles } from "@material-ui/core/styles";

export const backgroundColor = theme("mode", {
  light: "#FFF",
  dark: "#1E1E1E",
});
export const AccordionBackground = theme("mode", {
  light: "#48B0DB29",
  dark: "#2A3A41",
});
export const AccordionTextColor = theme("mode", {
  light: "#1E1E1E",
  dark: "white",
});
export const InputAdornmentIcon = theme("mode", {
  light: "#A3A3A3",
  dark: "#E6E6E6",
});

export const textColor = theme("mode", {
  light: "#000000",
  dark: "#ffffff",
});
export const muiIonColor = theme("mode", {
  light: "#686F72",
  dark: "#ffffff",
});
export const tableRowEven = theme("mode", {
  light: "#ffffff",
  dark: "#2B2B2B",
});
export const tableRowOdd = theme("mode", {
  light: "#F2F2F2",
  dark: "#242424",
});

export const darkGrey = theme("mode", {
  light: "#000",
  dark: "#bfbcb6",
});

export const buttonBackgroundColor = theme("mode", {
  light: "#222",
  dark: "#eee",
});

export const buttonTextColor = theme("mode", {
  light: "#eee",
  dark: "#222",
});

export const lastWordColor = theme("mode", {
  light: "#2c3034",
  dark: "#65AED7",
});
export const darkBlue = theme("mode", {
  light: "#65AED7",
  dark: "#65AED7",
});

export const darkBackgroundColor = theme("mode", {
  light: "#65AED7",
  dark: "#2c3034",
});

export const darkThemeGrey = theme("mode", {
  light: "#DCDFE2",
  dark: "#52565B",
});

export const darkThemeBorder = theme("mode", {
  light: "#52565B80",
  dark: "#DCDFE290",
});

export const TablebackgroundColor = theme("mode", {
  light: "#F6F6F6",
  dark: "#202020",
});

export const OverviewTableColor = theme("mode", {
  light: "#C8E7F4",
  dark: "#2F4E5B",
});
export const ShadeBg = theme("mode", {
  light: "#BEF8B4",
  dark: "#1C3B21",
});

export const BorderShade = theme("mode", {
  light: "#e5e5ea",
  dark: "#3c3f43",
});

export const useTableStyles = makeStyles(() => {
  return {
    tableContainer: {
      borderRadius: "6px",
      overflow: "hidden",
      border: `1px solid ${theme("mode", {
        light: "#A3A3A3",
        dark: "#E6E6E6",
      })}`,
      "& .MuiPaper-root .MuiButtonBase-root": {
        color: "#121212 !important",
      },
    },
    root: {
      "& .MuiPaginationItem-root": {
        backgroundColor: theme("mode", { light: "#E8EAEC", dark: "#202020" }),
        border: `1px solid ${theme("mode", {
          light: "none",
          dark: "#2B2B2B",
        })}`,
        color: theme("mode", {
          light: "#333333 !important",
          dark: "white !important",
        }),
        borderRadius: "7px",
        margin: "2px",
        "&:hover": {
          backgroundColor: "#ccc",
          color: "#fff",
        },
        "&.Mui-selected": {
          backgroundColor: "#48B0DB",
          color: "#fff !important",
          border: "none",
          "&:hover": {
            backgroundColor: "#48b0db",
          },
        },
      },
    },
  };
});
