import React from "react";
import { slide as Menu } from "react-burger-menu";
import NotificationComponent from "../Notifications/Notifications";
import { NotificationBadge, UserBadge } from "../Notifications/Notifications";
import { DarkMode } from "../../styles/ThemePallette";

const HamburgerMenu = ({
  showNotifications,
  setNotifications,
  isAdmin,
  logout,
  adminItems,
  userItems,
  dropdownForRole,
  navItem,
  notificationCount,
  getNotifications,
  handleReadNotification,
  activeUsers,
  setActiveModal,
}) => {
  return (
    <Menu className="menu-bar" right width={"35%"}>
      <div className="image-wrapper-burger mb-2 me-2">
        <img
          className="logo-image w-10"
          height={10}
          src="../images/headerlogo.png"
          alt="TPE Logo"
        />
      </div>
      <NotificationComponent {...{ showNotifications, setNotifications }} />

      <ul className="navbar-nav me-auto">
        {navItem(`/contracts`, "Contracts", "mb-2 nav-item-contracts")}
        {navItem(`/support`, "Support Ticket", "mb-2 nav-item-support-ticket")}
        {isAdmin &&
          navItem(`/tpe_support`, "Support Progress", "mb-2 nav-item-support")}
        {navItem("", "Logout", "nav-item-logout", null, (e) => {
          e.preventDefault();
          logout(e);
        })}
      </ul>

      <div>
        <div className="d-flex mt-2">
          <NotificationBadge
            {...{
              getNotifications,
              handleReadNotification,
              setNotifications,
              notificationCount,
            }}
          />
          <UserBadge {...{ activeUsers, setActiveModal }} />
          <div className="nav-link nav-item-color">
            <DarkMode />
          </div>
        </div>
        <div className="mt-2 mb-2">
          {isAdmin
            ? dropdownForRole(true, adminItems)
            : dropdownForRole(false, userItems)}
        </div>
      </div>
    </Menu>
  );
};

export default HamburgerMenu;
